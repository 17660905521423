function initialState () {
  return {
    defaultMarket: {
      progressItem: 1,
      pdfFiles: [],
      allFilesChecked: false,
      userBonus: 0,
      userBonusTitle: ''
    },
    buyFromIssuer: {
      transactionFee: 0,
      transactionTotalAmount: 0,
      transactionTotalAmountWithBonus: 0,
    },
    buyFromInvestor: {
      transactionFee: 0,
      transactionTotalAmount: 0,
      transactionTotalAmountWithBonus: 0,
    },
    createBuyOffer: {
      offer: {
        sharesAmount: 0,
        price: 0,
        totalPrice: 0,
        transactionFee: 0,
        transactionTotalAmount: 0,
        transactionTotalAmountWithBonus: 0,
      }
    },
    createSellOffer: {
      offer: {
        sharesAmount: 0,
        price: 0,
        totalPrice: 0,
        transactionFee: 0,
        transactionTotalAmount: 0,
      }
    },
    sellToInvestor: {
      transactionFee: 0,
      transactionTotalAmount: 0,
    }
  }
}

export interface PdfFileInterface {
  text1: string;
  text2: string;
  checked: boolean;
  link: string;
}

export interface CreateOfferInterface {
  sharesAmount: number;
  price: number;
  totalPrice: number;
  transactionFee: number;
  transactionTotalAmount: number;
  transactionTotalAmountWithBonus?: number;
}

export enum SecondaryMarketStateMutations {
  setDefaultMarket = 'setDefaultMarket',
  setBuyFromIssuer = 'setBuyFromIssuer',
  setBuyFromInvestor = 'setBuyFromInvestor',
  setCreateBuyOffer = 'setCreateBuyOffer',
  setCreateSellOffer = 'setCreateSellOffer',
  setSellToInvestor = 'setSellToInvestor',
  resetState = 'resetState',
}

export interface DefaultMarket {
  progressItem: number;
  pdfFiles: Array<PdfFileInterface>;
  allFilesChecked: boolean;
  userBonus: number;
  userBonusTitle: string;
}

export interface BuyFromIssuer {
  transactionFee: number;
  transactionTotalAmount: number;
  transactionTotalAmountWithBonus: number;
}

export interface BuyFromInvestor {
  transactionFee: number;
  transactionTotalAmount: number;
  transactionTotalAmountWithBonus: number;
}

export interface CreateBuyOffer {
  offer: CreateOfferInterface;
}

export interface CreateSellOffer {
  offer: CreateOfferInterface;
}

export interface SellToInvestor {
  transactionFee: number;
  transactionTotalAmount: number;
}

export interface SecondaryMarket {
  defaultMarket: DefaultMarket;
  buyFromIssuer: BuyFromIssuer;
  buyFromInvestor: BuyFromInvestor;
  createBuyOffer: CreateBuyOffer;
  createSellOffer: CreateSellOffer;
  sellToInvestor: SellToInvestor;
}

export interface SecondaryMarketState {
  data: SecondaryMarket;
}

const secondaryMarket = initialState()

export const secondaryMarketModule = {
  state: (): SecondaryMarketState => ({
    data: secondaryMarket
  }),
  mutations: {
    setDefaultMarket (state: SecondaryMarketState, data: object) {
      Object.keys(state.data.defaultMarket).forEach((key: any) => {
        // @ts-ignore
        if(key === 'pdfFiles' && typeof data[key] == 'number') {
          //@ts-ignore
          state.data.defaultMarket.pdfFiles[data[key]].checked = !state.data.defaultMarket.pdfFiles[data[key]].checked

          if(state.data.defaultMarket.pdfFiles.every((item: any) => item.checked === true)) {
            state.data.defaultMarket.allFilesChecked = true
          } else {
            state.data.defaultMarket.allFilesChecked = false
          }
          //@ts-ignore
        } else if (data[key]) {
          // @ts-ignore
          state.data.defaultMarket[key] = data[key]
        }
      })
    },

    setBuyFromIssuer (state: SecondaryMarketState, offer: object) {
      Object.keys(state.data.buyFromIssuer).forEach((key: any) => {
        // @ts-ignore
        if (offer[key]) {
          // @ts-ignore
          state.data.buyFromIssuer[key] = offer[key]
        }
      })
    },

    setBuyFromInvestor (state: SecondaryMarketState, offer: object) {
      Object.keys(state.data.buyFromInvestor).forEach((key: any) => {
        // @ts-ignore
        if (offer[key]) {
          // @ts-ignore
          state.data.buyFromInvestor[key] = offer[key]
        }
      })
    },

    setCreateBuyOffer (state: SecondaryMarketState, offer: object) {
      Object.keys(state.data.createBuyOffer).forEach(key => {
        //@ts-ignore
        if (offer[key]) {
          //@ts-ignore
          state.data.createBuyOffer[key] = offer[key]
        }
      })
    },

    setCreateSellOffer (state: SecondaryMarketState, offer: object) {
      Object.keys(state.data.createSellOffer).forEach(key => {
        //@ts-ignore
        if (offer[key]) {
          //@ts-ignore
          state.data.createSellOffer[key] = offer[key]
        }
      })
    },

    setSellToInvestor (state: SecondaryMarketState, offer: object) {
      Object.keys(state.data.sellToInvestor).forEach((key: any) => {
        // @ts-ignore
        if (offer[key]) {
          // @ts-ignore
          state.data.sellToInvestor[key] = offer[key]
        }
      })
    },

    resetState (state: SecondaryMarketState) {
      const defaultState = initialState()
      Object.keys(state.data).forEach((key: any) => {
        // @ts-ignore
        state.data[key] = defaultState[key]
      })
    },
  },
  actions: {
    //
  }
}
