import store from '@/store';
import { getIdfaInfo } from './helper.service';

export let fbp = '';
let fbc = '';
export let ga = '';

export const segmentIdentifyUser = async () => {
  try {
    if ((await getIdfaInfo()).isAdTrackingLimited) return;
    // @ts-ignore
    window.analytics.identify(store.state.user.data.userId);
  } catch (err) {
    console.log('Err with Segment');
  }
};

/**
 * Track Event in Segment
 * @param event Event Name from Tracking Plan
 * @param properties Properties to Track
 */
export const segmentTrackEvent = async (event: string, properties?: object) => {
  try {
    const idfa = await getIdfaInfo();
    const userId = !idfa.isAdTrackingLimited
      ? store.state.user.data.userId
      : undefined;
    const IDFA = idfa.id;
    const context = {
      device: {
        advertisingId: IDFA,
        manufacturer: store.state.deviceInformation.manufacturer,
        model: store.state.deviceInformation.model,
        type: store.state.deviceInformation.type,
      },
      os: {
        version: store.state.deviceInformation.osVersion,
      },
      app: {
        name: store.state.appInfo.name,
        version: store.state.appVersion,
        namespace: store.state.appInfo.id,
        partnerId: store.state.appConfig.partnerId
      },
    };
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/camelcase
    window.analytics.track(
      event,
      {
        ...properties,
        loggedIn: store.state.loggedIn,
        userId,
        IDFA,
        fbp,
        fbc,
        ga,
        facebookClientId: fbc,
        googleClientId: ga,
        platform: store.state.appPlatform
      },
      context
    );
  } catch (err) {
    console.log('Err with Segment');
  }
};

/**
 * Track Event in Segment before IDFA was asked on Apple
 * @param event Event Name from Tracking Plan
 * @param properties Properties to Track
 */
export const segmentTrackEventBeforeIDFA = async (
  event: string,
  properties?: object
) => {
  try {
    const context = {
      device: {
        manufacturer: store.state.deviceInformation.manufacturer,
        model: store.state.deviceInformation.model,
        type: store.state.deviceInformation.type,
      },
      os: {
        name: store.state.deviceInformation.type,
        version: store.state.deviceInformation.osVersion,
      },
      app: {
        name: store.state.appInfo.name,
        version: store.state.appVersion,
        namespace: store.state.appInfo.id,
        partnerId: store.state.appConfig.partnerId
      },
    };
    // @ts-ignore
    window.analytics.track(
      event,
      { 
        ...properties,
        platform: store.state.appPlatform,
        userId: undefined 
      },
      context
    );
  } catch (err) {
    console.log('Err with Segment');
  }
};

/**
 * Get cookie value with name
 */
const getCookie = (cname: string) => {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  if (!ca) return;
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

/**
 * Retrieve facebook and google id's from cookies to send them to segment
 */
const initAnalytics = () => {
  fbp = getCookie('_fbp') || '';
  fbc = getCookie('_fbc') || '';
  ga = localStorage.getItem('appGoogleClientId') || getCookie('_ga') || '';
  ga = ga.replace('GA1.1.', '').replace('GA1.2.', '');
  setTimeout(() => {
    fbp = getCookie('_fbp') || '';
    fbc = getCookie('_fbc') || '';
    ga = localStorage.getItem('appGoogleClientId') || getCookie('_ga') || '';
    ga = ga.replace('GA1.1.', '').replace('GA1.2.', '');
  }, 1000);
  setTimeout(() => {
    fbp = getCookie('_fbp') || '';
    fbc = getCookie('_fbc') || '';
    ga = localStorage.getItem('appGoogleClientId') || getCookie('_ga') || '';
    ga = ga.replace('GA1.1.', '').replace('GA1.2.', '');
  }, 2000);
  setTimeout(() => {
    fbp = getCookie('_fbp') || '';
    fbc = getCookie('_fbc') || '';
    ga = localStorage.getItem('appGoogleClientId') || getCookie('_ga') || '';
    ga = ga.replace('GA1.1.', '').replace('GA1.2.', '');
  }, 5000);
};

initAnalytics();
