import { registerPlugin } from '@capacitor/core';

export interface FirebaseInstallationResult {
  error: boolean;
  info: string;
}

export interface FirebasePlugin {
  getFirebaseInstallationId(): Promise<FirebaseInstallationResult>;
}

export const FirebasePlugin = registerPlugin<FirebasePlugin>('FirebasePlugin')
