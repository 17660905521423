// import * as Sentry from '@sentry/capacitor';

// Sentry.init({
//   dsn: 'https://d71425272e994132991e5eab8a33b885@o459666.ingest.sentry.io/6229816',
//   release: 'brickwise-app@' + process.env.npm_package_version,
// });

export const handleError = (err: Error | unknown) => {
  if (window.location.href.indexOf('localhost') > -1) {
    console.log('[ERROR]', err);
  } else {
    console.log('[ERROR]', err);
    // Sentry.captureException(err)
  }
};

export const handleErrorMessage = (msg: string) => {
  if (window.location.href.indexOf('localhost') > -1) {
    console.log('[ERROR]', msg);
  } else {
    console.log('[ERROR]', msg);
    // Sentry.captureMessage(msg)
  }
};
