<template>
  <Teleport to="body">
    <SheetModal
      :isModalOpen="displayNativeModal"
      @closeClick="closeInfoModal"
    >
      <template v-slot:content>
        <div class="nativeInfoModal py-24 px-16">
          <div class="nativeInfoModalContainer">
            <div class="title titleMedium mb-16">{{ infoData.header }}</div>
            <div class="text-holder" v-for="(item, i) in infoData.descriptions" :key="i">
              <div class="text paragraphExtraSmall mb-8" :class="{ underlined : item.link }" @click="item.link ? openPdf(item.link) : ''">{{ item.text }}</div>
            </div>
            <div v-if="infoData.inputs && infoData.inputs.length > 0" class="inputsContainer mt-16">
              <div class="inputItem" v-for="(item, i) in infoData.inputs" :key="i">
                <AppInput
                  v-model="item.value"
                  :label="item.label"
                  :type="item.type"
                ></AppInput>
              </div>
            </div>
            <div v-if="infoData.buttons && infoData.buttons.length > 0" class="buttonsContainer mt-32">
              <div class="buttonItem" v-for="(item, i) in infoData.buttons" :key="i">
                <AppButton :type="item.type" @click="item.function ? item.function() : ''">{{ item.text }}</AppButton>
              </div>
            </div>
          </div>
        </div>
      </template>
    </SheetModal>
  </Teleport>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
//Interfaces & Enums
import { StoreInterface } from '@/store'
import { InfoModalMutations } from '@/store/modules/infoModal'
//Components
import SheetModal from '@/components/General/SheetModal.vue'
import AppInput from '@/components/General/v2/AppInput.vue'
import AppButton from '@/components/General/AppButton.vue'
//Other
import PDFPlugin from '@/plugins/pdf.plugin'

export default defineComponent({
  name: 'NativeInfoModal',
  components: {
    SheetModal,
    AppInput,
    AppButton
  },
  setup() {
    const store: StoreInterface = useStore()

    return {
      infoData: computed(() => store.state.infoModal.modalData),
      displayNativeModal: computed(() => store.state.infoModal.displayNativeModal),
      closeInfoModal:() => store.commit(InfoModalMutations.closeInfoModal)
    }
  },
  methods: {
    openPdf(link: string) {
      PDFPlugin.viewPDF({ url: link });
    }
  }
})
</script>

<style lang="scss">
.nativeInfoModal {
  text-align: left;

  .nativeInfoModalContainer {
    .title {
      color: var(--navy800);
    }
  
    .text-holder {
      .text {
        color: var(--navy600);
  
        &.underlined {
          text-decoration: underline;
        }
      }
    }

    .buttonsContainer {
      display: flex;
      flex-direction: column;

      .buttonItem {
        flex-grow: 1;
      }
    }
  }
}
</style>
