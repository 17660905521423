import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "spinner-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_IonRouterOutlet = _resolveComponent("IonRouterOutlet")!
  const _component_NativeInfoModal = _resolveComponent("NativeInfoModal")!
  const _component_IonApp = _resolveComponent("IonApp")!

  return (_openBlock(), _createBlock(_component_IonApp, {
    id: "app",
    class: "ionApp",
    onTouchmove: _ctx.touchMoveEvent,
    onTouchend: _ctx.touchEndEvent
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["loadingSpinnerContainer", { displaySpinner : _ctx.isLoadingSpinner }])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_ion_spinner, {
            name: "crescent",
            duration: 650
          })
        ])
      ], 2),
      _createVNode(_component_IonRouterOutlet, {
        ref: "ionRouter",
        animated: "true",
        mode: "ios"
      }, null, 512),
      (_ctx.appPlatform === _ctx.AppPlatform.app || _ctx.appPlatform === _ctx.AppPlatform.mobileWeb)
        ? (_openBlock(), _createBlock(_component_NativeInfoModal, { key: 0 }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["onTouchmove", "onTouchend"]))
}