import { App } from "@capacitor/app"
import { isPlatform } from "@ionic/core"
import { Storage } from "@ionic/storage"
import CryptoJS from "crypto-js"

export type CacheType = 'cachedAppConfig' | 'cachedAssets' | 'cachedVersion' | 'cachedUser' | 'cachedPlatformInformation' | 'cachedNotifications'
let appId: undefined | string = undefined
const encryptionSecret = 'bccb32c96e64b6eed5f3179b27bd9e7d364e9dc7f3e7fe6296082a83dfb7ec94'

const localStorage = new Storage({ name: 'brickwiseLocalStore'})
export const createLocalStorage = async () => {
  await localStorage.create()
}

export const ionicStorageSetItem = async (index: string, value: string) => {
  await localStorage.set(index, value);
}

export const ionicStorageGetItem = async (index: string): Promise<string> => {
  return await localStorage.get(index)
}

export const ionicStorageRemoveItem = async (index: string) => {
  await localStorage.remove(index)
}

export const ionicStorageClearAll = async () => {
  await localStorage.clear()
}

createLocalStorage()

const setLocalAppId = async () => {
  const appInfo = isPlatform('capacitor') ? await App.getInfo() : undefined;
    appId = appInfo?.id || 'com.brickwise.app.staging'
}


export const setCache = async (cacheType: CacheType, value: string): Promise<void> => {
  if (!appId) await setLocalAppId()
  await ionicStorageSetItem(`${appId}_${cacheType}`, value)
}

export const getCache = async (cacheType: CacheType): Promise<string> => {
  if (!appId) await setLocalAppId()
  return await ionicStorageGetItem(`${appId}_${cacheType}`)
}

export const removeCache = async (cacheType: CacheType): Promise<void> => {
  await ionicStorageRemoveItem(`${appId}_${cacheType}`)
}

export const setEncryptedCache = async (cacheType: CacheType, value: string): Promise<void> => {
  if (!appId) await setLocalAppId()
  const encryptedValue = CryptoJS.AES.encrypt(value, encryptionSecret).toString()
  await ionicStorageSetItem(`${appId}_${cacheType}`, encryptedValue)
}

export const getEncryptedCache = async (cacheType: CacheType): Promise<string> => {
  if (!appId) await setLocalAppId()
  const cachedItems = await ionicStorageGetItem(`${appId}_${cacheType}`)
  if (!cachedItems) return ''
  const decryptValue = CryptoJS.AES.decrypt(cachedItems, encryptionSecret)
  const value = decryptValue.toString(CryptoJS.enc.Utf8)
  return value
}
