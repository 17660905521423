import { config } from '@/helpers/environment.helper';
import { handleError } from '@/services/error.service';
import { convertBase64ToBlob } from '@/services/helper.service';
import store, { AppConfigInterface, AppPlatform } from '@/store';
import {
  IdentificationMethodEnum,
  FourthLineFrontendIdentification,
  BonusType,
  UserBonus,
  PayOutAccounts,
  ComplianceAction,
} from '@/types/interfaces';
import { TAssetStage } from '@brickwise/asset-validation';
import axios from 'axios';

export const middlewareApi = axios.create({
  baseURL: config.middlewareURL,
  timeout: 100000,
  withCredentials: true,
});

export const getAppConfigByAppId = (id: string) => {
  console.log('Load with app id ', id)
  return middlewareApi.get(`/api/v1/appConfig/${id}`)
}

export const getAppConfigByHostname = (hostname: string) => {
  console.log('Load with hostname ', hostname)
  return middlewareApi.get(`/api/v1/appConfig/webAppUrl?webAppURL=${hostname}`)
};

export const getAppConfig = async (): Promise<AppConfigInterface | null> => {
  try {
    if (store.state.appPlatform === AppPlatform.app) {
      const appId = store.state.appInfo?.id || 'com.brickwise.app.staging'
      const appConfigRequest = await getAppConfigByAppId(appId)
      return appConfigRequest.data.data as AppConfigInterface
    } else {
      const hostname = window.location.origin === 'https://localhost.brickwise.at:8080' ? 'https://staging.app.brickwise.at' : window.location.origin
      const appConfigRequest = await getAppConfigByHostname(hostname)
      return appConfigRequest.data.data as AppConfigInterface
    }
  } catch (err: any) {
    handleError(err)
    return null
  }
};

export const getAllAppConfigs = () => {
  return middlewareApi.get('/api/v1/appConfig/')
};

export const postRegisterUser = (user: Record<string, any>) => {
  return middlewareApi.post('/auth/register', user);
};

export const postVerifyMail = (token: string) => {
  return middlewareApi.post('/api/v1/user/verifyMail', { token });
};

export const postResendMail = (resendToken: string) => {
  return middlewareApi.post('/api/v1/user/resendMail', { resendToken });
};

export const getUserData = () => {
  return middlewareApi.get('/api/v1/user');
};

export const postPersonalInformation = (body: object) => {
  return middlewareApi.post('/api/v1/user/personalInformation', body);
};

export const postComplianceInformation = (PEP: boolean) => {
  return middlewareApi.post('/api/v1/user/complianceInformation', { PEP });
};

export const postPushToken = (pushToken: string) => {
  return middlewareApi.post('/api/v1/user/pushToken', { pushToken });
};

export const postFirebaseId = (firebaseId: string) => {
  return middlewareApi.post('/api/v1/user/firebaseId', { firebaseId });
};

export const postAdvertisingId = (
  advertisingId: string,
  appGoogleClientId: string,
  appFacebookId: string
) => {
  return middlewareApi.post('/api/v1/user/advertisingId', {
    advertisingId,
    appGoogleClientId,
    appFacebookId,
    userAgent: window.navigator.userAgent,
  });
};

export const postChangePassword = (body: object) => {
  return middlewareApi.patch('/api/v1/user/password', body);
};

export const getSocketConnection = async () => {
  try {
    return await middlewareApi.get('/api/v1/user/socketConnection');
  } catch (err) {
    return false
  }
};

export const postLogout = () => {
  console.log('SocketId', store.state.socketId);
  return middlewareApi.post('/auth/logout', {
    socketId: store.state.socketId,
  });
};

export const postKnowledgeAndExperience = (body: object) => {
  return middlewareApi.post('/api/v1/user/knowledgeAndExperience', body);
};

export const putKnowledgeAndExperience = (body: object) => {
  return middlewareApi.put('/api/v1/user/knowledgeAndExperience', body);
};

export const postPhoneNumber = (body: object) => {
  return middlewareApi.post('/api/v1/user/phone', body);
};

export const postAccountPayout = (body: object) => {
  return middlewareApi.post('/api/v1/user/accountInformation', body);
};

export const postCompanyRegistration = (body: object) => {
  return middlewareApi.post('/api/v1/company', body);
};

export const putAttachCompanyToUser = (companyId: string) => {
  return middlewareApi.put('/api/v2/user/company', { companyId });
};

// export const postIdentificationDocument = (body: object) => {
//   return middlewareApi.post('/api/v1/user/document', body)
// }

export const getUserEmail = (email: string) => {
  return middlewareApi.get(`/api/v1/user/emailAvailable/${email}`);
};

export const postCancelRegistration = (body: object) => {
  return middlewareApi.post('/api/v1/user/cancelRegistration', body);
};

export const getFrontendVersion = () => {
  return middlewareApi.get('/api/v1/utils/frontendVersion');
};

export const getFeatureFlags = () => {
  return middlewareApi.get('/api/v1/utils/featureFlags');
};

export const getFees = () => {
  return middlewareApi.get('/api/v1/utils/fees');
};

export const getReferralLink = () => {
  return middlewareApi.get('/api/v1/bonus/referralLink');
};

export const postReminder = (body: { type: string; date: Date }) => {
  return middlewareApi.post('/api/v1/reminder', body);
};

export const getMiddlewareAssets = () => {
  return middlewareApi.get('/api/v2/assets');
};

export const getMiddlewareAsset = (assetId: string) => {
  return middlewareApi.get(`/api/v2/assets/${assetId}`);
};

export const getAssetOffers = (assetId: string) => {
  return middlewareApi.get(`/api/v2/assets/${assetId}/offers`);
};

export const getAssets = (params?: string) => {
  if (params) return middlewareApi.get(`/api/v1/assets?ids=${params}`);
  return middlewareApi.get('/api/v1/assets');
};

export const getEnergyData = (assetId: string) => {
  return middlewareApi.get(`/api/v2/assets/${assetId}/energyData`);
};

export const postResetPassword = (email: string) => {
  return middlewareApi.post(`/auth/reset-password`, { email });
};

export const postFinishPasswordReset = (token: string, passwordNew: string) => {
  return middlewareApi.post(`/auth/reset-password/finish`, { token, passwordNew });
};

export const getAllOpenBonuses = (): Promise<{
  data: { data: Array<UserBonus> };
}> => {
  return middlewareApi.get('/api/v1/bonus/allOpenBonuses');
};

export const getBonusForInvestment = (

  investmentType: TAssetStage,
  assetId?: any
): Promise<{ data: { data: UserBonus } }> => {
  return middlewareApi.get(
    `/api/v1/bonus/bonusForInvestment?investmentType=${investmentType}&assetId=${assetId}`
  );
};
export const postNoKnowledgeAndExperience = () => {
  return middlewareApi.post('/api/v1/user/noKnowledgeAndExperience');
};

export const putNoKnowledgeAndExperience = () => {
  return middlewareApi.put('/api/v1/user/noKnowledgeAndExperience');
};

export const patchIdentificationData = (body: object) => {
  return middlewareApi.patch('/api/v1/user/identificationData', body);
};

export const postIdNowId = (body: object) => {
  return middlewareApi.post('/api/v1/user/IdNowId', body);
};

export const postStartIdNowId = () => {
  return middlewareApi.post('/api/v1/user/startIdNowId');
};

export const postInitMoneyOut = (amount: number, payoutAccountId: string) => {
  return middlewareApi.post('/api/v1/payment/initmoneyout', { amount, payoutAccountId });
};

export const postMoneyOut = (transactionId: string, smsCode: string) => {
  return middlewareApi.post('/api/v1/payment/moneyout', { transactionId, smsCode });
};

export const likeAsset = (assetId: string) => {
  return middlewareApi.post('/api/v1/utils/like', { assetId });
};

export const unlikeAsset = (assetId: string) => {
  return middlewareApi.delete('/api/v1/utils/like', { data: { assetId } });
};

export const postInitPrimaryInvestment = (assetId?: any, shares?: number) => {
  return middlewareApi.post(`/api/v1/assets/initInvestment/${assetId}`, { shares });
};

export const cancelInitPrimaryInvestment = (transactionId: string) => {
  return middlewareApi.post('/api/v1/assets/cancelTransaction', { transactionId });
};

export const getTransactionsMetaData = (assetId: string) => {
  return middlewareApi.get(`/api/v1/assets/transactionMetadata/${assetId}`);
};

export const getDiagramData = (assetId: string) => {
  return middlewareApi.get(
    `/api/v1/assets/transactionMetadata/${assetId}?fields=diagramData`
  );
};

export const postInterestedParty = (offerId: string) => {
  return middlewareApi.post('/api/v1/bulletinboard/offer/interestedParty', { offerId });
};

export const createPlatformAccount = (platform: string) => {
  return middlewareApi.post('/api/v2/user/copy-me', { destinationPlatformProvider: platform })
}

export const isAllowedToCreateAccount = (platform: string) => {
  return middlewareApi.post('/api/v2/user/copy-me/allowed', { destinationPlatformProvider: platform })
}

export const switchPlatformSession = (platform: string) => {
  return middlewareApi.post('auth/switchSession', { destinationPlatform: platform })
}

export const postNewOffer = (offer: Record<string, any>) => {
  return middlewareApi.post('/api/v1/bulletinboard/offer', offer);
};

export const getPrivateContactDetails = (offerId: string) => {
  return middlewareApi.get(`/api/v1/bulletinboard/offer/privateContactDetails/${offerId}`)
}

export const postSigningRequest = (offerId: string) => {
  return middlewareApi.post('/api/v1/bulletinboard/offer/signingRequest', { offerId })
}

export const postDeleteOffer = (offerId: string) => {
  return middlewareApi.post('/api/v1/bulletinboard/offer/deleteRequest', { offerId })
}

export const postRegisterPrivateSecurityApp = (publicKey: string) => {
  return middlewareApi.post('api/v1/securityApp/registerPrivate', { publicKey })
}

export const postDisconnectSecurityApp = () => {
  return middlewareApi.post('api/v1/securityApp/disconnect')
}

export const checkBonusCode = async (
  code: string
): Promise<{
  bonusAmount: number;
  code: string;
  bonusType: BonusType;
  assetId?: string;
  minimumNumberOfShares?: number;
  minimumVolume?: number;
} | null> => {
  try {
    const bonusCode = await middlewareApi.get(`/api/v1/bonus/bonusCode/${code}`);
    if (!bonusCode.data.data) return null;
    return bonusCode.data.data;
  } catch (err) {
    return null;
  }
};

export const postUseBonusCode = (code: string) => {
  return middlewareApi.post(`/api/v1/bonus/bonusCode/use/${code}`);
};

// New Registration Flow
export const putUserNameAndAdditionalInformation = async (
  firstName: string,
  lastName: string,
  marketingNewsletter: boolean, additionalInformation: {
    referralCode?: string;
    affiliatePartnerId?: string;
  }
): Promise<boolean> => {
  try {
    await middlewareApi.put('/api/v2/user', {
      firstName,
      lastName,
      marketingNewsletter,
      referralCode: additionalInformation.referralCode,
      affiliatePartnerId: additionalInformation.affiliatePartnerId
    });
    return true;
  } catch (error) {
    return false;
  }
};

export const putUserAddress = async (
  street: string,
  city: string,
  PLZ: string,
  domicile: string
): Promise<boolean> => {
  try {
    await middlewareApi.put('/api/v2/user/personalInformation/address', {
      street,
      city,
      PLZ,
      domicile,
    });
    return true;
  } catch (error) {
    return false;
  }
};

export const putUserBirthDate = async (
  birthDate: string
): Promise<{ data: boolean; error: any | null }> => {
  try {
    await middlewareApi.put('/api/v2/user/personalInformation/birthDate', {
      birthDate,
    });
    return { data: true, error: null };
  } catch (err: any) {
    return { data: false, error: err.response.data.error };
  }
};

export const putUserBirthPlace = async (
  birthCountry: string,
  birthCity: string
): Promise<boolean> => {
  try {
    await middlewareApi.put('/api/v2/user/personalInformation/birthPlace', {
      birthCountry,
      birthCity,
    });
    return true;
  } catch (error) {
    return false;
  }
};

export const putUserNationality = async (
  nationality: string
): Promise<boolean> => {
  try {
    await middlewareApi.put('/api/v2/user/personalInformation/nationality', {
      nationality,
    });
    return true;
  } catch (error) {
    return false;
  }
};

export const postComplianceInformationNew = async (
  PEP: boolean
): Promise<boolean> => {
  try {
    await middlewareApi.post('/api/v1/user/complianceInformation', { PEP });
    return true;
  } catch (error) {
    return false;
  }
};

export const putUserPhone = async (phone: string): Promise<void> => {
  await middlewareApi.put('/api/v2/user/phone', { phone });
};

export const putUserTaxId = async (taxId: string, country: string): Promise<void> => {
  await middlewareApi.put('/api/v2/user/account/taxId', { taxId, country });
};

export const putUserIban = async (iban: string, VAT?:boolean): Promise<void> => {
  await middlewareApi.put('/api/v2/user/account/iban', { iban, VAT });
};

export const putUserChurchMembership = async (churchMember: boolean): Promise<void> => {
  await middlewareApi.put('/api/v2/user/account/churchTax', { hasChurchTax: churchMember });
};

export const putSocialSecurityNumber = async (socialSecurityNumber: string): Promise<void> => {
  await middlewareApi.put('/api/v2/user/personalInformation/socialSecurityNumber', { socialSecurityNumber });
};

export const putFinancialAdvisor = async (financialAdvisor: string): Promise<void> => {
  await middlewareApi.put('/api/v2/user/marketingData/financialAdvisor', { financialAdvisor });
};

export const putMarketingNewsletter = async (value: boolean): Promise<void> => {
  await middlewareApi.put('/api/v2/user/account/marketingNewsletter',  { marketingNewsletter: value });
};

export const getRealpartAuthorizationDocument = (assetId: string) => {
  return middlewareApi.get(`/api/v1/assets/${assetId}/documents/realpart-authorization`, { responseType: 'arraybuffer' })
}

export const getRealpartBuyContractDocument = (assetId: string) => {
  return middlewareApi.get(`/api/v1/assets/${assetId}/documents/realpart-buy-contract`, { responseType: 'arraybuffer' })
}

export const putAnalytics = async (parameters: object): Promise<void> => {
  try {
    await middlewareApi.put('/api/v2/user/analytics', parameters);
  } catch (err) {
    console.log(err);
  }
};

export const postAnalyticsEvent = async (parameters: object): Promise<void> => {
  try {
    await middlewareApi.post('/api/v1/utils/analyticsEvent', parameters);
  } catch (err) {
    console.log(err);
  }
};

export const getIdentificationMethod =
  async (): Promise<IdentificationMethodEnum | null> => {
    try {
      const identification = await middlewareApi.get('/api/v1/identification');
      return identification.data.data;
    } catch (err) {
      return null;
    }
  };

export const getAcceptedDocumentsForFourthline = async (): Promise<
  string | undefined
> => {
  try {
    const countries = await middlewareApi.get(
      '/api/v1/identification/fourthLine/acceptedDocuments'
    );
    return JSON.stringify(countries.data);
  } catch (err) {
    return;
  }
};

export const createFourthlineIdentification = async (): Promise<boolean> => {
  try {
    await middlewareApi.post('/api/v1/identification/fourthLine');
    return true;
  } catch (err) {
    return false;
  }
};

export const postNewFourthlineVerificationWithDataString = async (
  data: string
): Promise<boolean> => {
  try {
    const blob = convertBase64ToBlob(data);
    const formData = new FormData();
    formData.append('file', blob);
    await middlewareApi.post('/api/v1/identification/fourthLine/verification', formData);

    return true;
  } catch (err) {
    console.log('Error', err);
    return false;
  }
};

export const getFourthLineIdentification =
  async (): Promise<FourthLineFrontendIdentification | null> => {
    try {
      const identification = await middlewareApi.get(
        '/api/v1/identification/fourthLine/verification'
      );
      if (!identification.data.data) return null;
      return identification.data.data;
    } catch (err) {
      return null;
    }
  };

  export const getFourthLineBAVSession = async (): Promise<string | null> => {
    try {
      const sessionRequest = await middlewareApi.get(
        '/api/v1/identification/fourthLine/bavSession'
      );
      if (!sessionRequest.data.data) return null;
      return sessionRequest.data.data;
    } catch (err) {
      return null;
    }
  };

export const postNewSigningRequest = async (): Promise<boolean> => {
  try {
    await middlewareApi.post('/api/v1/identification/fourthLine/signingRequest');
    return true;
  } catch (err) {
    return false;
  }
};

export const postSignatureRequest = async (deviceData: {
  deviceLanguage: string;
  deviceRegion: string;
  deviceModel: string;
  deviceLat: string;
  deviceLng: string;
}): Promise<boolean> => {
  try {
    await middlewareApi.post('/api/v1/identification/fourthLine/signature', deviceData);
    return true;
  } catch (err) {
    return false;
  }
};

export const postSelfieForSignature = async (
  data: string
): Promise<boolean> => {
  try {
    const blob = convertBase64ToBlob(data);
    const formData = new FormData();
    formData.append('file', blob);
    await middlewareApi.post(
      '/api/v1/identification/fourthLine/signature/selfie',
      formData
    );

    return true;
  } catch (err) {
    console.log('Error', err);
    return false;
  }
};

export const postAuthorizeSignature = async (): Promise<boolean> => {
  try {
    await middlewareApi.post('/api/v1/identification/fourthLine/signature/authorize');
    return true;
  } catch (err) {
    return false;
  }
};

export const postOtpForSignature = async (otp: string): Promise<boolean> => {
  try {
    await middlewareApi.post('/api/v1/identification/fourthLine/signature/otp', { otp });
    return true;
  } catch (err) {
    return false;
  }
};

export const postResendOtpForSignature = async (): Promise<boolean> => {
  try {
    await middlewareApi.post('/api/v1/identification/fourthLine/signature/resend');
    return true;
  } catch (err) {
    return false;
  }
};

export const postGenerateIDNowIdent = async (): Promise<string | null> => {
  try {
    const response = await middlewareApi.post('/api/v1/identification/IdNow');
    if (!response || !response.data.data) return null;
    return response.data.data.idNowId;
  } catch (err) {
    return null;
  }
};

export const getEstimatedWaitingTimeIDNow = async (): Promise<boolean> => {
  try {
    await middlewareApi.get('/api/v1/identification/IDNow/estimatedwaitingtime');
    return true;
  } catch (err) {
    return false;
  }
};

export const postComplianceInformationTerms = async (body: any) => {
  return await middlewareApi.put('/api/v2/user/complianceInformation/terms', body);
};

export const postUserActivate = async () => {
  return await middlewareApi.post('/api/v2/user/activate');
};

export const getPayoutAccounts = async (): Promise<PayOutAccounts[]> => {
  try {
    const accounts = await middlewareApi.get('/api/v1/payment/payoutAccounts');
    return accounts.data.data;
  } catch (err) {
    return [];
  }
};

export const postComplianceLog = async (
  action: ComplianceAction
): Promise<boolean> => {
  try {
    const deviceMetaData = window.navigator ? window.navigator.userAgent : '';
    const timestamp = new Date();

    await middlewareApi.post('/api/v1/utils/complianceLog', {
      action,
      deviceMetaData,
      timestamp,
    });
    return true;
  } catch (err) {
    return false;
  }
};

export const getAffiliateLinkWithId =async (affiliateId: string): Promise<string | null> => {
  try {
    const req = await middlewareApi.get(`/api/v1/affiliate/id/${affiliateId}/url`)
    return req.data.data.url
  } catch(err) {
    return null
  }
}
