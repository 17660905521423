import { Sleep } from './../services/helper.service';
import { createRouter, createWebHistory } from '@ionic/vue-router';
import { nextTick } from 'vue';
import { RouteRecordRaw } from 'vue-router';
import { checkForPlatformAndConfig } from '@/helpers/HelperFunctions'

//Default
const InitialScreen = () => import('@/views//InitialScreens/InitialScreen.vue')
const NetworkError = () => import('@/views/NetworkError.vue')
const DownloadMobileApp = () => import('@/views/Desktop/DownloadMobileApp.vue')
const FinishExternalAuth = () => import('@/views/FinishExternalAuth.vue')
const EmailVerification = () => import('@/views/EmailVerification.vue')
const PaymentRedirect = () => import('@/views/PaymentRedirect.vue')
const ResetPasswordToken = () => import('@/views/ResetPasswordToken.vue')

import appRoutes from '@/router/app/app'
import mobileBrowserRoutes from '@/router/browser/mobile'
import desktopBrowserRoutes from '@/router/browser/desktop'

const routes: Array<RouteRecordRaw> = [
  {
    name: 'InitialScreen',
    path: '/',
    component: InitialScreen,
  },
  {
    name: 'NetworkError',
    path: '/networkError',
    component: NetworkError,
  },
  {
    name: 'DownloadMobileApp',
    path: '/downloadMobileApp',
    component: DownloadMobileApp,
  },
  {
    name: 'FinishExternalAuth',
    path: '/finishExternalAuth',
    component: FinishExternalAuth,
  },
  {
    name: 'Redirect',
    path: '/redirect',
    component: PaymentRedirect,
  },
  {
    name: 'EmailVerification',
    path: '/verify/:token',
    component: EmailVerification,
  },
  {
    name: 'ResetPasswordToken',
    path: '/password-reset/:token',
    component: ResetPasswordToken,
  },
  ...appRoutes,
  ...mobileBrowserRoutes,
  ...desktopBrowserRoutes,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async () => {
  await checkForPlatformAndConfig()
})

router.afterEach(async (to, from) => {
  if(from.name === 'InitialScreen') {
    const lottie = await (window as any).lottie
    if(lottie && lottie.splashscreen) {
      //Sleep 350, wait for page transition
      await Sleep(500)
      await lottie.splashscreen.hide()
    }
  }

  nextTick(() => {
    document.title = to.name as string
  })
})

export default router;
