import { modalController } from "@ionic/vue";
import { AppPlatform } from "@/store"
import { openDesktopInfoModal } from "@/helpers/HelperFunctions";
import { TextFieldTypes } from "@ionic/core";

export interface InfoModalState {
  displayNativeModal: boolean;
  modalData: ModalDataInterface;
}

export enum InfoModalMutations {
  openInfoModal = 'openInfoModal',
  closeInfoModal = 'closeInfoModal'
}

export interface InfoModalButtonInterface {
  type: string;
  text: string;
  function?: Function;
}

export interface InfoModalInputInterface {
  type?: TextFieldTypes;
  label?: string;
  value?: string;
}

export interface ModalDataInterface {
  header?: string;
  descriptions?: Array<{ text?: string; link?: string }>;
  inputs?: Array<InfoModalInputInterface>;
  buttons?: Array<InfoModalButtonInterface>;
}

export const infoModalModule = {
  state: (): InfoModalState => ({
    displayNativeModal: false,
    modalData: {} as ModalDataInterface
  }),
  mutations: {
    openInfoModal(state: InfoModalState, data: ModalDataInterface) {
      //@ts-ignore
      const appPlatform = this.state.appPlatform
      state.modalData = data
      if(appPlatform === AppPlatform.app || appPlatform === AppPlatform.mobileWeb) {
        state.displayNativeModal = true
      } else {
        openDesktopInfoModal(state.modalData)
      }
    },

    closeInfoModal(state: InfoModalState) {
      //@ts-ignore
      const appPlatform = this.state.appPlatform
      if(appPlatform === AppPlatform.app || appPlatform === AppPlatform.mobileWeb) {
        state.displayNativeModal = false
      } else {
        modalController.dismiss()
      }
    }
  },
};
