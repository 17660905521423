<template>
  <IonApp
    id="app"
    class="ionApp"
    v-on:touchmove="touchMoveEvent"
    v-on:touchend="touchEndEvent"
  >
    <div class="loadingSpinnerContainer" :class="{ displaySpinner : isLoadingSpinner }">
      <div class="spinner-content">
        <ion-spinner name="crescent" :duration="650"></ion-spinner>
      </div>
    </div>
    <IonRouterOutlet ref="ionRouter" animated="true" mode="ios" />
    <NativeInfoModal v-if="appPlatform === AppPlatform.app || appPlatform === AppPlatform.mobileWeb" />
  </IonApp>
</template>

<script lang="ts">
/* eslint-disable @typescript-eslint/camelcase */
import {
  IonApp,
  IonRouterOutlet,
  isPlatform,
  IonSpinner
} from '@ionic/vue';
import { touchMoveEvent, touchEndEvent } from './helpers/ModalBackgroundHelper';
import { defineComponent, computed, getCurrentInstance } from 'vue';
import { StoreInterface, StateMutations, AppInfoInterface, AppPlatform } from '@/store';
import { useStore } from 'vuex';
import { StatusBar } from '@awesome-cordova-plugins/status-bar';
import { getUserSession, postIchAppLoginWithCode } from './api/centralisedApi';
import { RegisterUserStateMutations } from '@/store/modules/registerUser';
import {
  getFeatureFlags,
  getFrontendVersion,
  postFirebaseId,
  postPushToken,
  getFees
} from './api/middlewareApi';
import { Network } from '@capacitor/network';
import { FingerprintAIO } from '@awesome-cordova-plugins/fingerprint-aio';
import {
  PushNotifications,
  ActionPerformed,
  Token,
  PushNotificationSchema
} from '@capacitor/push-notifications';
import { AssetStateActions } from '@/types/v2/asset/enums'
import { UserStateActions, UserStateMutations } from './store/modules/user';
import {
  reestablishSocketConnection,
  initializeSocketConnection
} from './services/socketio';
import {
  FirebaseDynamicLinks,
  DeepLinkOpen
} from '@pantrist/capacitor-firebase-dynamic-links';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import {
  segmentTrackEventBeforeIDFA,
  segmentTrackEvent,
  ga,
  fbp,
segmentIdentifyUser
} from './services/analytics.service';
import { DynamicLinkTypes } from './types/interfaces';
import { Keyboard } from '@capacitor/keyboard';
import { useBackButton, useIonRouter } from '@ionic/vue';
import { Device } from '@capacitor/device';

import { FirebasePlugin } from '@/plugins/firebase.plugin';
import { useRoute } from 'vue-router';
import { openReminderDialog } from './services/helper.service';
import { SplashScreen } from '@capacitor/splash-screen';

import NativeInfoModal from '@/components/General/v2/NativeInfoModal.vue';
import { getCache, getEncryptedCache, removeCache, setCache } from './store/storage/ionicStorage';
import { MessagingStateActions } from './store/modules/messaging';
import { handleErrorMessage } from './services/error.service';
import { Browser } from '@capacitor/browser';
import { handleAppConfig } from './helpers/HelperFunctions';

declare let dataLayer: Array<any>;

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonSpinner,
    IonRouterOutlet,
    NativeInfoModal
  },
  setup() {
    const store: StoreInterface = useStore();
    const route = useRoute()
    const router = useIonRouter();
    let openedAppFirstTime = false;
    const appPlatform = computed(() => store.state.appPlatform).value

    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const pjson = require('../package.json');
    const platform = isPlatform('ios') ? 'ios' : 'android';

    // eslint-disable-next-line @typescript-eslint/camelcase
    segmentTrackEventBeforeIDFA('Application Opened', {
      from_background: false,
      version: pjson.version,
      platform
    });

    // Send first open event
    if (!store.state.appOpenedFirstTime) {
      openedAppFirstTime = true;
      store.commit(StateMutations.setAppOpenedFirstTime);
    }
    const currentInstance = getCurrentInstance() as any
    const isLoadingSpinner = computed(() => store.state.isLoadingSpinner)

    useBackButton(99, () => {
      if(isLoadingSpinner.value) return
      switch (route.fullPath) {
        case `/${appPlatform}/marketplace`:
          App.exitApp()
          break;
        //indexRoutes
        case `/${appPlatform}/investments`:
        case `/${appPlatform}/account`:
        case `/${appPlatform}/inbox`:
        case `/${appPlatform}/support`:
          router.push(`/${appPlatform}/marketplace`)
          break;
        //onboardingRoutes
        case `/${appPlatform}/identification`:
        case `/${appPlatform}/knowledge-and-experience`:
          openReminderDialog(router, currentInstance.appContext.config.globalProperties.$t)
          break;
        default:
          if(!router.canGoBack()) {
            router.replace(`/${appPlatform}/marketplace`)
          } else {
            router.back()
          }
          break;
      }
    });
    return {
      AppPlatform,
      touchMoveEvent,
      touchEndEvent,
      router,
      state: computed(() => store.state),
      openedAppFirstTime,
      platform,
      pjson,
      appPlatform: computed(() => store.state.appPlatform),
      isLoadingSpinner: computed(() => isLoadingSpinner.value),
      appConfig: computed(() => store.state.appConfig),
      shouldNotShowIntroSlides: computed(() => store.state.introSlidesWatched),
      setPushToken: (pushToken: string) =>
        store.commit(StateMutations.setPushToken, pushToken),
      setFirebaseId: (firebaseId: string) =>
        store.commit(StateMutations.setFirebaseId, firebaseId),
      setAskedForBiometrics: (date: string) =>
        store.commit(StateMutations.setAskedForBiometrics, date),
      setBiometricsEnabled: (bio: boolean) =>
        store.commit(StateMutations.setBiometricsEnabled, bio),
      setBiometricsType: (type: string) =>
        store.commit(StateMutations.setBiometricsType, type),
      setLoggedIn: (loggedIn: boolean) =>
        store.commit(StateMutations.setLoggedIn, loggedIn),
      setTemporaryUrlSafe: (url: string) =>
        store.commit(StateMutations.setTemporaryUrlSafe, url),
      setAppInfo: (appInfo: AppInfoInterface) => store.commit(StateMutations.setAppInfo, appInfo),
      setEmptyUser: () => store.commit(UserStateMutations.setEmptyUser),
      fetchAssets: () => store.dispatch(AssetStateActions.fetchAssets),
      loadNotifications: () => store.dispatch(MessagingStateActions.fetchInitialNotifications),
      loadUserData: (userData?: any) => store.dispatch(UserStateActions.loadUserData, userData),
      resetRegisteUserState: () =>
        store.commit(RegisterUserStateMutations.resetState),
      setRegisterUserRegister: (user: any) =>
        store.commit(RegisterUserStateMutations.setRegisterUserRegister, user),
      setFeatureFlags: (featureFlags: any) =>
        store.commit(StateMutations.setFeatureFlags, featureFlags),
      setDefaultFee: (fee: any) =>
        store.commit(StateMutations.setDefaultFee, fee),
      setKeyboardIsActive: (isActive: boolean) =>
        store.commit(StateMutations.setKeyboardIsActive, isActive),
      setAppVersion: (appVersion: string) =>
        store.commit(StateMutations.setAppVersion, appVersion),
      setIsAppActive: (active: boolean) =>
        store.commit(StateMutations.setIsAppActive, active),
      setAppPlatform: (platform: AppPlatform) =>
        store.commit(StateMutations.setAppPlatform, platform),
      setTemporaryTokenSafe: (token: string) => 
        store.commit(StateMutations.setTemporaryTokenSafe, token),
      setDeviceInformation: (deviceInformation: {
        model: string;
        type: string;
        osVersion: string;
        manufacturer: string;
      }) =>
        store.commit(StateMutations.setDeviceInformation, deviceInformation),
      setInstallParameter: (installParameters: object) =>
        store.commit(StateMutations.setInstallParameter, installParameters),
      setLoadingSpinner: (isLoading: boolean) => store.commit(StateMutations.setLoadingSpinner, isLoading)
    };
  },
  data() {
    return {
      firstConnect: true,
      urlToForward: '',
      blockedRouting: false,
      isNetworkConnected: false,
    };
  },
  async beforeCreate() {
    await this.loadUserData()
    const lottie = (window as any).lottie
    if(lottie && lottie.splashscreen && isPlatform('ios')) {
      await lottie.splashscreen.hide()
      await lottie.splashscreen.show('public/assets/splash.json', false)
    }
    await SplashScreen.hide()
  },
  async created() {
    // 1. Without Network we can't do anything
    await this.checkNetworkStatus()
    if(!this.isNetworkConnected) return this.router.replace('/networkError')
    // 2. Let's set the platform and device info
    await Promise.all([
      this.handleAppPlatform(),
      this.getDeviceInformation(),
    ])

    // 3. Init handlers and first opening handling
    this.initGeneralHandlers();
    if (this.openedAppFirstTime) this.sendAppOpenedFirsTimeEvent()
    if (this.appPlatform === AppPlatform.app) {
      // Native
      this.initMobileHandlers()
      this.checkDeviceBiometrics();
      this.setupFirebaseServices();
      await this.getAppInformation()
      // We need to sync the cookies
      if (isPlatform('ios')) 
        this.doCookieHandling();
    } else {
      // Web
      this.urlHandler(window.location.href)
    }

    // 4. Load the most important data from web; appConfig first as we need to set the header
    await handleAppConfig()
    await Promise.all([
      this.versionCheck(),
      this.fetchAssets(),
      this.getPlatformInformation()
    ])

    // TODO: Also add handling if the user is not yet loggedIn
    // 5. Routing
    // If network or version is false we should not do any routing
    if (this.blockedRouting) return
    if (this.shouldNotShowIntroSlides) {
      const userLoggedIn = await this.checkUserSession();
      // if we are on Desktop we do not care if we are loggedIn or not
      if (userLoggedIn || this.appPlatform === AppPlatform.desktopWeb || this.appPlatform === AppPlatform.mobileWeb || this.urlToForward.search('verificationToken') !== -1 || this.urlToForward.search('passwordToken') !== -1) {
        if (userLoggedIn) this.afterFirstLoginHandling()
        this.appOpeningUrlHelper(this.urlToForward)
        this.urlToForward = ''
      } else {
        if (this.urlToForward && this.urlToForward !== `/${this.appPlatform}/biometricLogin`) {
          this.setTemporaryUrlSafe(this.urlToForward)
          this.urlToForward = ''
        }
        this.appLoginUrlHelper()
      }
    } else {
      this.handleFirstScreen();
    }
  },
  methods: {
    t(key: string) {
      return this.$t(`Menu.${key}`);
    },

    async checkNetworkStatus() {
      try {
        this.isNetworkConnected = await (await Network.getStatus()).connected
      } catch(err) {
        console.log(err)
      }
    },

    async handleAppPlatform() {
      let platform = null as any

      if(isPlatform('capacitor')) {
        platform = AppPlatform.app
      } else if (window.innerWidth < 601) {
        platform = AppPlatform.mobileWeb
      } else {
        platform = AppPlatform.desktopWeb
      }

      await this.setAppPlatform(platform)
    },

    handleFirstScreen() {
      if(this.appConfig.featureFlags.partnerSelectionPage && this.openedAppFirstTime) {
        this.router.replace(`/${this.appPlatform}/selectPartner`)
      } else if (this.appConfig.featureFlags.introSlidesSignUp) {
        this.router.replace(`/${this.appPlatform}/introSlides`)
      } else {
        this.router.replace(`/${this.appPlatform}/register`)
      }
    },

    appOpeningUrlHelper(url: string) {
      // TODO: Add Desktop Logic
      if (!url || url === '/') {
        this.router.replace(`/${this.appPlatform}/marketplace`)
      } else if (url.search(`/${this.appPlatform}/biometricLogin`) !== -1) {
        this.router.navigate(url, 'forward', 'pop');
      } else {
        this.router.replace(url)
      }
    },

    appLoginUrlHelper() {
      // TODO: fix; Not entirely flawless as the biometric login would block any forward routing
      if (this.urlToForward === `/${this.appPlatform}/biometricLogin`) {
        this.router.navigate(`/${this.appPlatform}/biometricLogin`, 'forward', 'pop');
      } else {
        if (this.router.canGoBack()) {
          this.router.replace(`/${this.appPlatform}/login`);
        } else {
          this.router.navigate(`/${this.appPlatform}/login`, 'forward', 'pop');
        }
        setTimeout(() => this.router.replace(`/${this.appPlatform}/login`), 300)
      }
    },

    /** Check user session */
    async checkUserSession(): Promise<boolean> {
      const session = await getUserSession();
      if (session) {
        this.setLoggedIn(true);
        // Work with user cache
        const cachedUser = await getEncryptedCache('cachedUser');
        if (cachedUser) {
          const userData = JSON.parse(cachedUser)
          await this.loadUserData(userData)
        }
        this.loadUserData();
        if (this.state.socketId) {
          reestablishSocketConnection();
        } else {
          initializeSocketConnection();
        }
        return true
      } else {
        removeCache('cachedUser')
        this.setLoggedIn(false);
        this.setEmptyUser();
        return false
      }
    },

    /**
     * Check if there is an update to Download
     */
    async versionCheck() {
      try {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const pjson = require('../package.json');
        const currentVersion = pjson.version;
        this.setAppVersion(currentVersion);

        const newestVersion = (await getFrontendVersion()).data.data.version;
        if (newestVersion <= currentVersion) return;

        this.blockedRouting = true;
        this.router.replace(`/${this.appPlatform}/updateApp`)
      } catch (e) {
        console.log('Check Version Error', e);
      }
    },

    async getDeviceInformation() {
      const device = await Device.getInfo();
      if (device)
        this.setDeviceInformation({
          model: device.model,
          type: device.platform,
          osVersion: device.osVersion,
          manufacturer: device.manufacturer
        });
    },

    async getAppInformation() {
      const appInfo = await App.getInfo();
      if (appInfo) await this.setAppInfo(appInfo)
    },

    async getPlatformInformation() {
      try {
        const cachedPlatformInformation = await getCache('cachedPlatformInformation')
        const fetchPlatformInformation = async () => {
          const promises = await Promise.all([
            getFees(),
            getFeatureFlags()
          ])
          const fees = promises[0].data.data.fees;
          this.setDefaultFee(fees);
          const featureFlags = promises[1].data.data.featureFlags;
          this.setFeatureFlags(featureFlags);
          const platformInformation = { fees, featureFlags }
          setCache('cachedPlatformInformation', JSON.stringify(platformInformation))
        }
        if (cachedPlatformInformation) {
          const { fees, featureFlags } = JSON.parse(cachedPlatformInformation)
          this.setDefaultFee(fees);
          this.setFeatureFlags(featureFlags);
          fetchPlatformInformation();
        } else {
          await fetchPlatformInformation();
        }
      } catch (e) {
        console.log('Get Platform Information', e);
      }
    },

    doCookieHandling() {
      // @ts-ignore
      if (window.plugins.cookie) {
        // @ts-ignore
        window.plugins.cookie.executeXHR(
          'https://brickwise.at',
          function(response: any) {
            // response: ok
          },
          function(error: any) {
            console.log('error: ' + error);
          }
        );
      }
      // @ts-ignore
      else StatusBar.overlaysWebView(false);
    },

    sendAppOpenedFirsTimeEvent() {
      setTimeout(
        () =>
          segmentTrackEventBeforeIDFA('Application Installed', {
            version: this.pjson.version,
            platform: this.platform,
            ...this.state.installParameters,
            appGoogleClientId: ga,
            appFacebookId: fbp
          }),
        5000
      );
      if(this.appConfig.partnerId === 'brickwise') {
        dataLayer.push({
          event: `cv_opened_app_${this.platform}`
        });
      }
    },

    /**
     * Init All Handlers
     */
    initGeneralHandlers(): void {
      App.addListener('appStateChange', async ({ isActive }) => {
        this.setIsAppActive(isActive);
        if (isActive) {
          // eslint-disable-next-line @typescript-eslint/camelcase
          segmentTrackEvent('Application Opened', { from_background: true });
          // No need to check session if we are in the verification flow
          if (this.urlToForward.search('verificationToken') !== -1 || this.urlToForward.search('passwordToken') !== -1) {
            this.appOpeningUrlHelper(this.urlToForward)
            this.urlToForward = ''
            return
          }
          /* const userLoggedIn = */ await this.checkUserSession();
          // Deactivated this handling for now
          // if (!userLoggedIn) {
          //   // if we are on Desktop we do not care if we are loggedIn or not
          //   if (this.appPlatform === AppPlatform.desktopWeb) return
          //   this.appLoginUrlHelper()
          // } else if (this.urlToForward !== '') {
          //   this.appOpeningUrlHelper(this.urlToForward)
          //   this.urlToForward = ''
          // }
        } else {
          segmentTrackEvent('Application Backgrounded');
        }
      });

      Network.addListener('networkStatusChange', status => {
        if (status.connected) {
          if (this.firstConnect && isPlatform('ios')) {
            this.firstConnect = false;
            return;
          }
          this.blockedRouting = false;
          if (this.$route.fullPath === '/networkError') {
            this.router.replace(`/${this.appPlatform}/marketplace`);
          } else {
            return
          }
        } else {
          console.log('disconnect');
          this.blockedRouting = true;
          this.router.replace(`/networkError`);
        }
      });
      
    },

    initMobileHandlers(): void {
      App.addListener('appUrlOpen', (data: URLOpenListenerEvent) =>
        this.appUrlHandler(data)
      );

      FirebaseDynamicLinks.addListener('deepLinkOpen', (data: DeepLinkOpen) =>
        this.firebaseUrlHandler(data)
      );

      Keyboard.addListener('keyboardWillShow', e => {
        this.setKeyboardIsActive(true);
        if (isPlatform('ios')) {
          const app: HTMLElement = document.querySelector('ion-app')!;
          app.style.marginBottom = (e as any).keyboardHeight + 'px';

          const sheet: any = document.querySelector('.sheetModalMobile');
          sheet.style.marginBottom = (e as any).keyboardHeight + 'px';
        }
      });

      Keyboard.addListener('keyboardWillHide', () => {
        this.setKeyboardIsActive(false);
        if (isPlatform('ios')) {
          const app: HTMLElement = document.querySelector('ion-app')!;
          app.style.marginBottom = 0 + 'px';

          const sheet: any = document.querySelector('.sheetModalMobile');
          sheet.style.marginBottom =  0 + 'px';
        }
      });
    },

    appUrlHandler(data: URLOpenListenerEvent) {
      console.log('Debug: App Url opener', JSON.stringify(data));
      // handle false firebase deeplink and block the session based routing if there is a handled url
      if (
        data.url.search(
          'No%20pre%2Dinstall%20link%20matched%20for%20this%20device%2E'
        ) !== -1
      )
        return;
      // TODO: Remove?
      if (data.url.search('https://brickwise.page.link') !== -1) return;
      this.urlHandler(data.url);
    },

    firebaseUrlHandler(data: DeepLinkOpen) {
      // On Android the urlHandler and the firebase Url handler is triggered on the opening
      if (isPlatform('android') && !this.openedAppFirstTime) return
      this.urlHandler(data.url);
    },

    /**
     * Firebase and Firebase Push handling
     */
    async setupFirebaseServices() {
      const firebase = await FirebasePlugin.getFirebaseInstallationId();
      if (!firebase.error) this.setFirebaseId(firebase.info);

      if (this.state.pushEnabled) {
        PushNotifications.register();
        PushNotifications.addListener('registration', (token: Token) => {
          this.setPushToken(token.value);
        });

        PushNotifications.addListener(
          'pushNotificationReceived',
          (notification: PushNotificationSchema) => {
            segmentTrackEvent('Push Notification Received', {
              data: notification.data,
              id: notification.id
            });
          }
        );

        // open site if send with push notification
        PushNotifications.addListener(
          'pushNotificationActionPerformed',
          (action: ActionPerformed) => {
            segmentTrackEvent('Push Notification Tapped', {
              action: action.actionId,
              data: action.notification.data,
              id: action.notification.id
            });
            if (action.actionId !== 'tap') return;
            const data: { action: string; info: string } =
              action.notification.data;
            // TODO: implement action
          }
        );
      }
    },

    /**
     * Check if and what biometric sensors can bes used
     */
     async checkDeviceBiometrics() {
      try {
        const fingerprint = await FingerprintAIO.isAvailable();
        if (!fingerprint) {
          this.setAskedForBiometrics(Date.now().toString());
          this.setBiometricsEnabled(false);
        } else {
          this.setBiometricsType(fingerprint);
        }
      } catch (err) {
        this.setAskedForBiometrics(Date.now().toString());
        this.setBiometricsEnabled(false);
      }
      // Check if biometrics is enable
      if (this.state.biometricsEnabled && this.state.lastUser && this.state.lastUser.email) {
        this.urlToForward = `/${this.appPlatform}/biometricLogin`
      }
    },

    /**
     * Handle the url from the url handlers accordingly
     * @param url the url to handle
     */
    async urlHandler(url: string) {
      console.log('Debug: Handle URL', url);
      // Handle Dynamic Link special flow
      const urlParams = new URL(url).searchParams;
      if (url.search('linkType') !== -1) {
        const linkType = urlParams.get('linkType') || '';
        const linkMessage = urlParams.get('linkMessage') || '';
        segmentTrackEventBeforeIDFA('Deep Link Opened', {
          url,
          type: linkType
        });
        this.handleSpecialDynamicLinks(linkType, linkMessage);
        return;
      }

      // Handle ich App Auth special flow on Web for mobile
      const token = urlParams.get('code')
      if (token) {
        // Native handling
        // First IOS handling -> there we receive the url call directly
        // then Android handling
        // Last Browser handling
        if (isPlatform('capacitor') && isPlatform('ios')) {
          this.setTemporaryTokenSafe(token || '')
          Browser.close()
        } else if(window.innerWidth < 601) {
          this.urlToForward = `/finishExternalAuth?platform=ichapp&token=${token}`
        } else {
          // Desktop handling
          await this.ichAppLoginHandling(token)
        }
        return
      }

      // Handle ich App Error flow
      const error = urlParams.get('error')
      if (error) {
        // IOS handling
        if (isPlatform('capacitor') && isPlatform('ios')) {
          Browser.close()
        } else if(window.innerWidth < 601) {
          this.urlToForward = `/finishExternalAuth?platform=ichapp&token=failed`
        }
        return
      }

      // handle ich App Auth special flow on Native
      if (url.search('token') !== -1 && url.search('platform') !== -1) {
        this.setTemporaryTokenSafe(urlParams.get('token') || '')
        return
      }

      // handle redirect payment for security app
      if (url.search('redirect') !== -1) {
        this.blockedRouting = true
        return
      }

      // Track Deep Link Opened
      const utm_id = urlParams.get('utm_id');
      const utm_source = urlParams.get('utm_source');
      const utm_medium = urlParams.get('utm_medium');
      const utm_campaign = urlParams.get('utm_campaign');
      const utm_term = urlParams.get('utm_term');
      const utm_content = urlParams.get('utm_content');
      const webGoogleClickId = urlParams.get('gclid');
      const webFacebookClickId = urlParams.get('fbclid');
      const webGoogleClientId = urlParams.get('googleClientId');
      const webFacebookId = urlParams.get('facebookClientId');

      if (this.openedAppFirstTime) {
        this.setInstallParameter({
          ...(utm_id && { utm_id }),
          ...(utm_source && { utm_source }),
          ...(utm_medium && { utm_medium }),
          ...(utm_campaign && { utm_campaign }),
          ...(utm_term && { utm_term }),
          ...(utm_content && { utm_content }),
          ...(webGoogleClickId && { webGoogleClickId }),
          ...(webFacebookClickId && { webFacebookClickId }),
          ...(webGoogleClientId && { webGoogleClientId }),
          ...(webFacebookId && { webFacebookId })
        });
      }
      segmentTrackEventBeforeIDFA('Deep Link Opened', {
        url,
        type: 'link',
        utm_id,
        utm_content,
        utm_campaign,
        utm_source,
        utm_term,
        utm_medium,
        webGoogleClickId,
        webFacebookClickId,
        webGoogleClientId,
        webFacebookId
      });

      // Clear Query Parameter
      const cleanUrl = this.removeQueryParameters(url);
      // Clear url
      const site = cleanUrl
        // Remove the branded app url schema
        .replace(/^(\w+\.)?brickwise\.app\//, '')
        .replace(/^(?:[^.]+\.)*bwplatform\.app\//, '')
        // Remove the branded app local url schema
        .replace(/^[^.]+\.(brickwise):\/\//, '')
        .replace(/^[^.]+\.(bwplatform):\/\//, '')
        // Remove the old general schema
        .replace('brickwise://', '')
        .replace('https://', '')
        .replace('http://', '')
        .replace('app.brickwise.at/', '')
        .replace('staging.', '')
        .replace('app/', '')
        .replace('desktopWeb/', '')
        .replace('mobileWeb/', '')
        // for local testing of verification links
        .replace('localhost.brickwise.at:8080/', '')
      const subSiteArray = site.split('/');

      // handle registerSpecialFlow and to introSlides flow
      if (subSiteArray.length === 2 && (subSiteArray[0] === 'register' || subSiteArray[0] === 'verify')) {
        return this.urlToForward = `/${this.appPlatform}/register?verificationToken=${subSiteArray[1]}`;
        //Handle Reset Password token redirect
      } else if (subSiteArray.length === 2 && subSiteArray[0] === 'password-reset') {
        return this.urlToForward = `/${this.appPlatform}/reset-password?passwordToken=${subSiteArray[1]}`;
      }

      const straightForwardUrls = [
        'marketplace',
        'investments',
        'account',
        'support',
        'register',
        'login',
        'settings',
        'reset-password',
        'identification',
        'security-app-integration',
        'imprint-details'
      ];

      if (subSiteArray.length === 1) {
        if (straightForwardUrls.includes(site)) {
          this.urlToForward = `/${this.appPlatform}/${site}`;
        } else if (subSiteArray[0] === 'imprint') {
          if (this.appPlatform === AppPlatform.app) {
            this.urlToForward = `/${this.appPlatform}/settings?option=imprintDetails`
          } else {
            this.urlToForward = `/${this.appPlatform}/imprint-details`
          }
        } else {
          this.urlToForward = `/${this.appPlatform}/marketplace`;
        }
      } else {
        if (subSiteArray[0] === 'forum') {
          this.urlToForward = `/${this.appPlatform}/asset/${subSiteArray[1]}`
        } else if (subSiteArray[0] === 'transaction') {
          this.urlToForward = `/${this.appPlatform}/account?transaction=${subSiteArray[1]}`
        } else if (subSiteArray[0] === 'assets' || subSiteArray[0] === 'asset') {
          if (subSiteArray.length === 2) {
            this.urlToForward = `/${this.appPlatform}/asset/${subSiteArray[1]}`
          } else {
            this.urlToForward = `/${this.appPlatform}/asset/${subSiteArray[1]}/${subSiteArray[2]}`
          }
        } else if (subSiteArray[0] === 'settings') {
          this.urlToForward = `/${this.appPlatform}/settings?option=${subSiteArray[1]}`
        } else {
          this.urlToForward = `/${this.appPlatform}/marketplace`
        }
      }
    },

    removeQueryParameters(url: string) {
      return url.split('?')[0];
    },

    async ichAppLoginHandling(token: string) {
      // TODO: Add install parameters! Change Segment Event!
      try {
        this.setLoadingSpinner(true)
        await postIchAppLoginWithCode(token)

        this.setLoggedIn(true);
        await this.loadUserData()
        
        // Handle Socket Connection
        if (this.state.socketId) {
          reestablishSocketConnection();
        } else {
            initializeSocketConnection();
        }

        segmentIdentifyUser()
        this.afterFirstLoginHandling()

        this.setLoadingSpinner(false);
        if (!this.state.user.data.firstName && !this.state.user.data.lastName) {
          this.urlToForward = `/${this.appPlatform}/register?finishRegistration=true&hidePassword=true`
          this.router.navigate(this.urlToForward, 'forward', 'pop');
        } else {
          this.appOpeningUrlHelper('/')
        }
      } catch (err: any) {
        this.setLoadingSpinner(false)
        handleErrorMessage(err)
      }
    },

    /**
     * handle special dynamic link
     */

    handleSpecialDynamicLinks(linkType: string, linkMessage: string) {
      switch (linkType) {
        case DynamicLinkTypes.referral:
          this.setInstallParameter({
            utm_source: 'brickwise',
            utm_medium: 'affiliate',
            utm_campaign: 'af-065'
          });
          this.setRegisterUserRegister({ referralCode: linkMessage });
          break;
        case DynamicLinkTypes.affiliate:
          this.setInstallParameter({
            utm_source: 'brickwise',
            utm_medium: 'affiliate',
            utm_campaign: 'af-082'
          });
          this.setRegisterUserRegister({ affiliatePartnerId: linkMessage });
          break;
        case DynamicLinkTypes.startBonus:
          this.setRegisterUserRegister({
            email: atob(linkMessage).toLowerCase()
          });
          break;
        case DynamicLinkTypes.appStoreRating:
          // TODO: Add sharing logic here!
          this.urlToForward = `/${this.appPlatform}/marketplace`
      }
    },

    async afterFirstLoginHandling() {
      if (this.state.pushToken) await postPushToken(this.state.pushToken);
      if (this.state.firebaseId) await postFirebaseId(this.state.firebaseId);
      await this.loadNotifications()
    },
  }
});
</script>

<style lang="scss">
@font-face {
  font-family: 'Aestetico';
  font-style: normal;
  src: url(./assets/fonts/Latinotype_Aestetico_Regular.otf) format('opentype');
}

@font-face {
  font-family: 'Aestetico';
  font-weight: bold;
  src: url(./assets/fonts/aestetico_semi_bold.otf) format('opentype');
}

@font-face {
  font-family: 'Aestetico';
  font-weight: 500;
  src: url(./assets/fonts/aestetico_medium.otf) format('opentype');
}

* {
  font-family: Aestetico, sans-serif;
  --color: var(--navy800);
}

.ionApp {
  //TODO remove text-align:center check whole app
  text-align: center;
  margin-bottom: 0;
  transition: margin-bottom 420ms;
}

.loadingSpinnerContainer {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 99999999999;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;

  &.displaySpinner {
    opacity: 1;
    transition: background-color 200ms ease-in;
    background: rgba(0, 0, 0, 0.25);
    pointer-events: all;
  }

  .spinner-content {
    background: var(--white);
    padding: 48px;
    border-radius: 16px;

    ion-spinner {
      --color: var(--brand500);
      width: 64px;
      height: 64px;
    }
  }
}
</style>
