import { RouteRecordRaw } from 'vue-router';

//Desktop
const Desktop = () => import('@/views/Desktop/DesktopIndex.vue')
const DesktopLogin = () => import('@/views/Desktop/DesktopLogin.vue')
const DesktopResetPassword = () => import('@/views/Desktop/DesktopResetPassword.vue')
const DesktopImprintDetails = () => import('@/views/Desktop/DesktopImprint.vue')
const DesktopRegistration = () => import('@/views/Desktop/Identification/Registration.vue')
const DesktopIdentification = () => import('@/views/Desktop/Identification/Identification.vue')
const DesktopCompanyRegistration = () => import('@/views/Desktop/Identification/CompanyRegistration.vue')
const DesktopMarketplace = () => import('@/views/Desktop/MainTabs/DesktopMarketplace.vue')
const DesktopInvestments = () => import('@/views/Desktop/MainTabs/DesktopInvestments.vue')
const DesktopAccount = () => import('@/views/Desktop/MainTabs/DesktopAccount.vue')
const DesktopInbox = () => import('@/views/Desktop/MainTabs/DesktopInbox.vue')
const DesktopSettings = () => import('@/views/Desktop/MainTabs/DesktopSettings.vue')
const DesktopKnowledgeAndExperience = () => import('@/views/Desktop/KnowledgeAndExperience/DefaultKnowledgeAndExperience.vue')
const BuilderOwnerModelKnowledgeExperience = () => import('@/views/Desktop/KnowledgeAndExperience/BuilderOwnerModelKnowledgeExperience.vue')
const DesktopSupport = () => import('@/views/Desktop/MainTabs/DesktopSupport.vue')
const DesktopSupportWriteUs = () => import('@/views/Desktop/Support/WriteUs.vue')
const DesktopSupportCallBack = () => import('@/views/Desktop/Support/CallBack.vue')
const DesktopAssetDetails = () => import('@/views/Desktop/Asset/AssetDetails.vue')
const DesktopAssetInfo = () => import('@/views/Desktop/Asset/AssetInfo.vue')
const DesktopLocationDetails = () => import('@/views/Desktop/Asset/LocationDetails.vue')
const DesktopInvestmentDetails = () => import('@/views/Desktop/Asset/InvestmentDetails.vue')
const DesktopRentDetails = () => import('@/views/Desktop/Asset/RentDetails.vue')
const DesktopAssetRecords = () => import('@/views/Desktop/Asset/AssetRecords.vue')
const DesktopImprintInfo = () => import('@/views/Desktop/Asset/ImprintInfo.vue')
const BuilderOwnerModelInvestment = () => import('@/views/Desktop/Trading/BuilderOwnerModelInvestment.vue')
const DesktopPowerPlantPortfolioInvestment = () => import('@/views/Desktop/Trading/PowerPlantPortfolioInvestment.vue')
const DesktopPartnerInvestment = () => import('@/views/Desktop/Trading/PartnerInvestment.vue')
const DesktopPrimaryMarketInvestment = () => import('@/views/Desktop/Trading/PrimaryMarketInvestment.vue')
const DesktopSecondaryMarketTrade = () => import('@/views/Desktop/Trading/SecondaryMarketTrade.vue')
const DesktopSecondaryMarketBuyFlow = () => import('@/views/Desktop/Trading/SecondaryMarketBuyFlow.vue')
const DesktopSecondaryMarketSellFlow = () => import('@/views/Desktop/Trading/SecondaryMarketSellFlow.vue')
const DesktopCreateBuyOfferFlow = () => import('@/views/Desktop/Trading/CreateBuyOfferFlow.vue')
const DesktopCreateSellOfferFlow = () => import('@/views/Desktop/Trading/CreateSellOfferFlow.vue')
const NotFound = () => import('@/views/NotFound.vue')
const DesktopContinueRegistrationOnMobile = () => import('@/views/Desktop/DesktopContinueRegistrationOnMobile.vue')
const ComingSoon = () => import('@/views/Desktop/ComingSoon.vue')

const desktopBrowserRoutes: Array<RouteRecordRaw> = [
  {
    path: '/desktopWeb',
    component: Desktop,
    children: [
      {
        path: '',
        redirect: 'desktopWeb/marketplace'
      },
      {
        name: 'Desktop',
        path: "/:catchAll(.*)",
        component: NotFound,
      },
      {
        name: 'ComingSoon',
        path: 'comingSoon',
        component: ComingSoon,
      },
      {
        name: 'DesktopContinueRegistrationOnMobile',
        path: 'continue-on-mobile',
        component: DesktopContinueRegistrationOnMobile,
      },
      {
        name: 'DesktopLogin',
        path: 'login',
        component: DesktopLogin
      },
      {
        name: 'DesktopResetPassword',
        path: 'reset-password',
        component: DesktopResetPassword
      },
      {
        name: 'DesktopRegistration',
        path: 'register',
        component: DesktopRegistration
      },
      {
        name: 'DesktopCompanyRegistration',
        path: 'company-registration',
        component: DesktopCompanyRegistration
      },
      {
        name: 'DesktopIdentification',
        path: 'identification',
        component: DesktopIdentification
      },
      {
        name: 'DesktopImprintDetails',
        path: 'imprint-details',
        component: DesktopImprintDetails,
      },
      {
        name: 'DesktopMarketplace',
        path: 'marketplace',
        component: DesktopMarketplace
      },
      {
        name: 'DesktopInvestments',
        path: 'investments',
        component: DesktopInvestments
      },
      {
        name: 'DesktopAccount',
        path: 'account',
        component: DesktopAccount
      },
      {
        name: 'DesktopInbox',
        path: 'inbox',
        component: DesktopInbox
      },
      {
        name: 'DesktopSettings',
        path: 'settings',
        component: DesktopSettings
      },
      {
        name: 'DesktopKnowledgeAndExperience',
        path: 'knowledge-and-experience',
        component: DesktopKnowledgeAndExperience
      },
      {
        name: 'BuilderOwnerModelKnowledgeExperience',
        path: 'building/knowledge-and-experience',
        component: BuilderOwnerModelKnowledgeExperience
      },
      {
        name: 'DesktopSupport',
        path: 'support',
        component: DesktopSupport
      },
      {
        name: 'DesktopSupportWriteUs',
        path: 'support/write-us',
        component: DesktopSupportWriteUs,
      },
      {
        name: 'DesktopSupportCallBack',
        path: 'support/call-back',
        component: DesktopSupportCallBack,
      },
      {
        name: 'DesktopAssetDetails',
        path: 'asset/:id/details',
        component: DesktopAssetDetails
      },
      {
        name: 'DesktopAssetInfo',
        path: 'asset/:id/asset-info',
        component: DesktopAssetInfo
      },
      {
        name: 'DesktopLocationDetails',
        path: 'asset/:id/location-details',
        component: DesktopLocationDetails
      },
      {
        name: 'DesktopInvestmentDetails',
        path: 'asset/:id/investment-details',
        component: DesktopInvestmentDetails
      },
      {
        name: 'DesktopRentDetails',
        path: 'asset/:id/rent-details',
        component: DesktopRentDetails
      },
      {
        name: 'DesktopAssetRecords',
        path: 'asset/:id/asset-records',
        component: DesktopAssetRecords
      },
      {
        name: 'DesktopImprintInfo',
        path: 'asset/:id/imprint-info',
        component: DesktopImprintInfo,
      },
      {
        name: 'BuilderOwnerModelInvestment',
        path: 'building-investment/:id',
        component: BuilderOwnerModelInvestment
      },
      {
        name: 'DesktopPowerPlantPortfolioInvestment',
        path: 'powerplant-portfolio-investment/:id',
        component: DesktopPowerPlantPortfolioInvestment
      },
      {
        name: 'DesktopPartnerInvestment',
        path: 'partner-investment/:id',
        component: DesktopPartnerInvestment
      },
      {
        name: 'DesktopAssetInvestment',
        path: 'investment/:id',
        component: DesktopPrimaryMarketInvestment
      },
      {
        name: 'DesktopAssetTrade',
        path: 'trading/:id',
        component: DesktopSecondaryMarketTrade
      },
      {
        name: 'DesktopSecondaryMarketBuyFlow',
        path: 'trading/:id/buy/:offerId',
        component: DesktopSecondaryMarketBuyFlow
      },
      {
        name: 'DesktopSecondaryMarketSellFlow',
        path: 'trading/:id/sell/:offerId',
        component: DesktopSecondaryMarketSellFlow
      },
      {
        name: 'DesktopCreateBuyOfferFlow',
        path: 'trading/:id/create-buy-offer',
        component: DesktopCreateBuyOfferFlow
      },
      {
        name: 'DesktopCreateSellOfferFlow',
        path: 'trading/:id/create-sell-offer',
        component: DesktopCreateSellOfferFlow
      },
    ]
  },
]

export default desktopBrowserRoutes;