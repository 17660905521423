import { RouteRecordRaw } from 'vue-router';

const IntroSlides = () => import('@/views/InitialScreens/IntroSlides.vue')
const SelectPartner = () => import('@/views/InitialScreens/SelectPartner.vue')
const Login = () => import('@/views/NativeLogin.vue')
const Register = () => import('@/views/Identification/NativeRegister.vue')
const CompanyRegistration = () => import('@/views/Identification/NativeCompanyRegistration.vue')
const Identification = () => import('@/views/Native/Identification/Identification.vue')
const KnowledgeAndExperience = () => import('@/views/Identification/KnowledgeAndExperience.vue')
const BuilderOwnerModelKnowledgeExperience = () => import('@/views/Native/KnowledgeAndExperience/BuilderOwnerModelKnowledgeExperience.vue')
const ResetPassword = () => import('@/views/ResetPassword.vue')
const Settings = () => import('@/views/NativeSettings.vue')
const UpdateSecurityApp = () => import('@/views/SecurityApp/UpdateSecurityApp.vue')
const PrivateSecurityApp = () => import('@/views/SecurityApp/PrivateSecurityApp.vue')
const MarketPlace = () => import('@/views/MainTabs/NativeMarketplace.vue')
const Investments = () => import('@/views/MainTabs/NativeInvestments.vue')
const Account = () => import('@/views/MainTabs/NativeAccount.vue')
const Support = () => import('@/views/MainTabs/NativeSupport.vue')
const Inbox = () => import('@/views/MainTabs/NativeInbox.vue')
const InboxDetail = () => import('@/views/Inbox/InboxDetail.vue')
const InvestmentView = () => import('@/views/Asset/InvestmentView.vue')
const PowerPlantPortfolioInvestment = () => import('@/views/Native/Trading/PowerPlantPortfolioInvestment.vue')
const PartnerInvestment = () => import('@/views/Native/Trading/PartnerInvestment.vue')
const BuilderOwnerModelInvestment = () => import('@/views/Native/Trading/BuilderOwnerModelInvestment.vue')
const TradingView = () => import('@/views/Asset/TradingView.vue')
const BuyOfferOverview = () => import('@/views/Offers/BuyOfferOverview.vue')
const TradingOfferOverview = () => import('@/views/Offers/TradingOfferOverview.vue')
const ImprintInfo = () => import('@/views/Asset/ImprintInfo.vue')
const UpdateApp = () => import('@/views/Utils/UpdateApp.vue')
const Biometrics = () => import('@/views/Utils/NativeBiometrics.vue')
const Notifications = () => import('@/views/Utils/NativeNotifications.vue')
const BiometricLogin = () => import('@/views/BiometricLogin.vue')
const AddPayout = () => import('@/views/AddPayout.vue')
const SupportWriteUs = () => import('@/views/Support/SupportWriteUs.vue')
const SupportCallBack = () => import('@/views/Support/SupportCallBack.vue')
//Native - Asset
const NativeAsset = () => import('@/views/Asset/v2/NativeAsset.vue')
const ContinueRegistrationOnNative = () => import('@/views/Desktop/DesktopContinueRegistrationOnMobile.vue')

const mobileBrowserRoutes: Array<RouteRecordRaw> = [
  {
    name: 'MobileWebMarketPlace',
    path: '/mobileWeb/marketplace',
    component: MarketPlace,
  },
  {
    name: 'MobileWeInvestments',
    path: '/mobileWeb/investments',
    component: Investments,
  },
  {
    name: 'MobileWeAccount',
    path: '/mobileWeb/account',
    component: Account,
    props: true,
  },
  {
    name: 'MobileWeInbox',
    path: '/mobileWeb/inbox',
    component: Inbox,
  },
  {
    name: 'MobileWeInboxDetail',
    path: '/mobileWeb/inbox/:id',
    component: InboxDetail,
    props: true,
  },
  {
    name: 'MobileWeSupport',
    path: '/mobileWeb/support',
    component: Support,
  },
  {
    name: 'MobileWeLogin',
    path: '/mobileWeb/login',
    component: Login,
  },
  {
    name: 'MobileWeBiometricLogin',
    path: '/mobileWeb/biometricLogin',
    component: BiometricLogin,
  },
  {
    name: 'MobileWeIntroSlides',
    path: '/mobileWeb/introSlides',
    component: IntroSlides,
  },
  {
    name: 'MobileWeSelectPartner',
    path: '/mobileWeb/selectPartner',
    component: SelectPartner,
  },
  {
    name: 'MobileWeRegister',
    path: '/mobileWeb/register',
    component: Register,
  },
  {
    name: 'MobileWeCompanyRegistration',
    path: '/mobileWeb/company-registration',
    component: CompanyRegistration,
  },
  {
    name: 'MobileWeContinueRegistrationOnNative',
    path: '/mobileWeb/continue-on-native',
    component: ContinueRegistrationOnNative,
  },
  {
    name: 'MobileWeIdentification',
    path: '/mobileWeb/identification',
    component: Identification,
  },
  {
    name: 'MobileWeKnowledgeAndExperience',
    path: '/mobileWeb/knowledge-and-experience',
    component: KnowledgeAndExperience,
  },
  {
    name: 'MobileBuilderOwnerModelKnowledgeExperience',
    path: '/mobileWeb/building/knowledge-and-experience',
    component: BuilderOwnerModelKnowledgeExperience
  },
  {
    name: 'MobileWeResetPassword',
    path: '/mobileWeb/reset-password',
    component: ResetPassword,
  },
  {
    name: 'MobileWeSettings',
    path: '/mobileWeb/settings',
    component: Settings,
    props: true
  },
  {
    name: 'MobileWeUpdateSecurityApp',
    path: '/mobileWeb/update-security-app',
    component: UpdateSecurityApp,
  },
  {
    name: 'MobileWePrivateSecurityApp',
    path: '/mobileWeb/private-security-app',
    component: PrivateSecurityApp,
  },
  {
    name: 'MobileWeSupportWriteUs',
    path: '/mobileWeb/support/writeUs',
    component: SupportWriteUs,
  },
  {
    name: 'MobileWeSupportCallBack',
    path: '/mobileWeb/support/callBack',
    component: SupportCallBack,
  },
  {
    name: 'MobileWeNativeAsset',
    path: '/mobileWeb/asset/:id',
    component: NativeAsset,
  },
  {
    name: 'MobileWeInvestment',
    path: '/mobileWeb/investment/:id',
    component: InvestmentView,
    props: true,
  },
  {
    name: 'MobileWeNativePowerPlantPortfolioInvestment',
    path: '/mobileWeb/powerplant-portfolio-investment/:id',
    component: PowerPlantPortfolioInvestment
  },
  {
    name: 'MobileWeNativePartnerInvestment',
    path: '/mobileWeb/partner-investment/:id',
    component: PartnerInvestment
  },
  {
    name: 'MobileBuilderOwnerModelInvestment',
    path: '/mobileWeb/building-investment/:id',
    component: BuilderOwnerModelInvestment
  },
  {
    name: 'MobileWeTrading',
    path: '/mobileWeb/trading/:id',
    component: TradingView,
    props: true,
  },
  {
    name: 'MobileWeBuyOfferOverview',
    path: '/mobileWeb/buy-offer-overview/:id',
    component: BuyOfferOverview,
  },
  {
    name: 'MobileWeTradingOfferOverview',
    path: '/mobileWeb/trading-offer-overview/:id',
    component: TradingOfferOverview,
  },
  {
    name: 'MobileWeImprintInfo',
    path: '/mobileWeb/asset/:id/imprint-info',
    component: ImprintInfo,
  },
  {
    name: 'MobileWeUpdateApp',
    path: '/mobileWeb/updateApp',
    component: UpdateApp,
  },
  {
    name: 'MobileWeBiometrics',
    path: '/mobileWeb/biometrics',
    component: Biometrics,
  },
  {
    name: 'MobileWeNotifications',
    path: '/mobileWeb/notifications',
    component: Notifications,
  },
  {
    name: 'MobileWeAddPayout',
    path: '/mobileWeb/add-payout',
    component: AddPayout,
  },
]

export default mobileBrowserRoutes;
