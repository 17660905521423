import { ref } from 'vue';
import store from '@/store'

function initialModal() {
  return {
    backgroundOpacity: 0.956
  };
}

const modalData = ref({ ...initialModal() });

export function useModalData() {
  return {
    modalData
  };
}

export async function calculateBackgroundOpacity() {
  const modalData = useModalData().modalData.value
  const screenHeight = document.documentElement.clientHeight;
  const modalTopPosition = await document.getElementById('cardModal')!.getBoundingClientRect().top
  const modalVisibleInViewport = screenHeight - modalTopPosition
  const modalPercentageInViewport = modalVisibleInViewport / screenHeight
  modalData.backgroundOpacity = modalPercentageInViewport
}

export function touchMoveEvent(e: any) {
  if(!store.state.isModalBackground) return

  calculateBackgroundOpacity()
}

export function touchEndEvent(e: any) {
  if(!store.state.isModalBackground) return
  const modalData = useModalData().modalData.value

  if(modalData.backgroundOpacity < 0.5) {
    modalData.backgroundOpacity = 0
  }
}