import { getSocketConnection } from '@/api/middlewareApi'
import { config } from '@/helpers/environment.helper'
import store, { StateMutations } from '@/store'
import { UserStateActions } from '@/store/modules/user'
import { io } from 'socket.io-client'
export const socket = io(config.middlewareURL)

export const registerSocket = (email: string| null = null, socketId: string| null = null) => {
  socket.emit('register', { email, socketId })
  console.log('register Socket', socketId, socket)
}

export const reestablishSocketConnection = () => {
  const { email, socketId } = store.getters.getSocketData
  // console.log('reestablishSocketConnection ayo', email, socketId)
  registerSocket(email, socketId)
}

export const initializeSocketConnection = async () => {
  const socketIdRes = await getSocketConnection();
  if (socketIdRes && socketIdRes.data.socketId) {
    store.commit(StateMutations.setSocketId, socketIdRes.data.socketId),
    registerSocket(
      store.state.user.data.email,
      socketIdRes.data.socketId
    );
  }
}

socket.on('update', data => {
  store.dispatch(UserStateActions.loadUserData)
  console.log('update', data)
})

socket.on('update_assets', async (assetId) => {
  console.log('update asset!', assetId)
})
