import { registerPlugin } from '@capacitor/core';

export interface SafeAreaPlugin {
  getSafeAreaInsets(): Promise<SafeAreaInsets>;
}
interface SafeArea {
  top: number;
  right: number;
  bottom: number;
  left: number;
}
export interface SafeAreaInsets {
  insets: SafeArea;
}

const SafeArea = registerPlugin<SafeAreaPlugin>('SafeAreaPlugin');

export default SafeArea;
