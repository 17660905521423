<template>
  <div
    v-if="appPlatform === AppPlatform.app || appPlatform === AppPlatform.mobileWeb"
    class="sheetModalMobile"
    :class="[
      transformParams.showTransition ? 'show-transition' : '',
      isModalOpen ? 'active' : ''
    ]"
    @click="$emit('closeClick')"
  >
    <div id="sheetModalWrapper"
      @click="$event.stopPropagation()"
      class="sheet-modal-wrapper"
      :class="[
        transformParams.showTransition ? 'show-transition' : '',
        isModalOpen ? 'active' : ''
      ]"
      :style="[
        transformParams.translateY !== null ? 'transform: translateY(' + transformParams.translateY + 'px)' : '',
        `padding-bottom: ${safeArea.bottom}px`
      ]"
    >
      <div class="sheet-modal-content">
        <div class="swipeIndicator"></div>
        <slot name="content"></slot>
      </div>
    </div>
  </div>
  <div v-if="appPlatform === AppPlatform.desktopWeb" class="sheetModalDesktop" @click="$emit('closeClick')">
    <div id="sheetModalWrapper" class="sheet-modal-wrapper" @click="$event.stopPropagation()">
      <div class="sheet-modal-content">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { createGesture } from '@ionic/vue';
import { useStore } from 'vuex';
//Interfaces & Enums
import { StoreInterface, AppPlatform } from '@/store';
//Other
import { Sleep } from '@/services/helper.service';
import { clamp } from '@/helpers/HelperFunctions'

export default defineComponent({
  name: 'SheetModal',
  emits: ['closeClick'],
  props: {
    isModalOpen: {
      type: Boolean,
      required: true
    }
  },
  watch: {
    async isModalOpen() {
      if(this.isModalOpen) {
        //Wait for modal to be displayed (200ms transition time) and then enable swipeToClose
        await Sleep(200)
        this.enableSwipeToClose()
      } else {
        this.swipeToCloseGesture.destroy()
      }
    }
  },
  data() {
    return {
      transformParams: {
        translateY: null as any,
        showTransition: true
      },
      swipeToCloseGesture: null as any
    }
  },
  setup() {
    const store: StoreInterface = useStore();

    return {
      AppPlatform,
      clamp,
      appPlatform: computed(() => store.state.appPlatform),
      safeArea: computed(() => store.state.safeAreaInsets)
    }
  },
  methods: {
    enableSwipeToClose() {
      const element = document.querySelectorAll('.sheet-modal-wrapper.active')[0]!
      let bottomSheetHeight = 0;
      let startPosY = 0;
      let swipeVelocity = 0;
      this.swipeToCloseGesture = createGesture({
        el: element,
        gestureName: 'swipeToClose',
        direction: 'y',
        threshold: 0,

        onStart: (detail) => {
          this.transformParams.showTransition = false
          bottomSheetHeight = element.getBoundingClientRect().height;
          startPosY = this.clamp(
            detail.currentY - (window.innerHeight - bottomSheetHeight),
            0,
            bottomSheetHeight
          );
          swipeVelocity = 0;
        },

        onMove: (detail) => {
          this.transformParams.showTransition = false
          this.transformParams.translateY = this.clamp(
            detail.currentY -
              (window.innerHeight - bottomSheetHeight) -
              startPosY,
            0,
            bottomSheetHeight
          );
          swipeVelocity = detail.velocityY;
        },

        onEnd: () => {
          this.transformParams.showTransition = true
          if (swipeVelocity > 0.75 || this.transformParams.translateY > bottomSheetHeight / 2) {
            this.$emit('closeClick')
          }
          this.transformParams.translateY = null;
        }
      })

      this.swipeToCloseGesture.enable(true)
    }
  }
});
</script>

<style lang="scss" scoped>
.sheetModalMobile {
  transition-property: background-color;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;

  &.show-transition {
    transition: background-color 200ms ease-in;
  }

  &:not(.active) {
    background-color: transparent;
    pointer-events: none;

    .bottom-sheet {
      transform: translateY(100%);
    }
  }
  .sheet-modal-wrapper {
    transition-property: none;
    width: 100%;
    background: white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: relative;
    z-index: 1; // in front of keyboard-placeholder

    &.show-transition {
      transition: all 200ms ease-in;
    }

    &:not(.active) {
      opacity: 0;
      pointer-events: none;
      transform: translateY(100%);
    }

    .sheet-modal-content {
      position: relative;
      text-align: left;
      color: var(--navy800);

      .swipeIndicator {
        width: 48px;
        height: 4px;
        background: var(--navy300);
        margin: 8px auto 0px auto;
      }
    }
  }
}

.sheetModalDesktop {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);

  .sheet-modal-wrapper {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    box-shadow: 0px -4px 16px 0px rgba(38, 49, 59, 0.12);
    border-radius: 16px;
    background: var(--white);
  }

  @media(max-width: $tablet-small) {
    display: none;
  }
}
</style>
