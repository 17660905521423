import store from '@/store'
import { postAdvertisingId, postReminder } from '@/api/middlewareApi';
import { alertController, isPlatform } from '@ionic/vue';
import { handleError } from './error.service';
import { fbp, ga, segmentTrackEvent } from './analytics.service';
import Idfa, { AdvertisingInfoResponse } from '@/plugins/idfa.plugin';
import { parsePhoneNumber } from 'awesome-phonenumber';

let idfaResult: AdvertisingInfoResponse | undefined = undefined;

/**
 * Get ad identifier on Android and Ios
 */
export const getIdfaInfo = async (): Promise<AdvertisingInfoResponse> => {
  if (!isPlatform('capacitor')) {
    return { id: '', isAdTrackingLimited: false };
  }
  try {
    if (!idfaResult) {
      idfaResult = await Idfa.getAdvertisingInfo();
      await postAdvertisingId(idfaResult.id || '', ga, fbp);
    }
    return idfaResult;
  } catch (err) {
    handleError(err);
    return { isAdTrackingLimited: true, id: null };
  }
};

// /** Get ios-user tracking permission informations */
// export const getIdfaInfo = async (): Promise<{ status: IDFA_STATUS; idfa: string }> => {
//   return new Promise((resolve, reject) => {
//     if (
//       !(
//         isPlatform('capacitor') &&
//         isPlatform('ios') &&
//         // @ts-ignore
//         window.cordova
//       )
//     ) {
//       // if non-ios platform
//       resolve({ status: IDFA_STATUS.TRACKING_PERMISSION_AUTHORIZED, idfa: '' });
//       return;
//     }
//     const success = (res: IdfaResponse) => {
//       resolve({ status: res.trackingPermission, idfa: res.idfa });
//     };
//     const failure = (err: unknown) => {
//       resolve({ status: IDFA_STATUS.TRACKING_PERMISSION_DENIED, idfa: '' });
//     };
//     // @ts-ignore
//     window.cordova.exec(success, failure, 'idfa', 'getInfo', []);
//   });
// }

export const Sleep = (milliseconds: number): Promise<void> => {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
};

const postReminderFunction = async (type: string, date: Date) => {
  try {
    await postReminder({ type, date });
  } catch (err) {
    handleError(err);
  }
};

export const openReminderDialog = async (router: any, t: (key: string) => string,) => {
  const appPlatform = store.getters.getAppPlatform
  const type = 'videoIdentification'
  const alert = await alertController.create({
    header: t('ReminderDialog.header'),
    message: t('ReminderDialog.message'),
    cssClass: 'reminderDialogAlert',
    buttons: [
      {
        text: t('ReminderDialog.button1'),
        handler: () => {
          const currentDate = new Date();
          const tomorrow = new Date(
            currentDate.setDate(currentDate.getDate() + 1)
          );
          const tomorrowEvening = new Date(tomorrow.setHours(18, 0, 0, 0));
          postReminderFunction(type, tomorrowEvening);
          segmentTrackEvent('User Identification Reminder', {
            flow: 'Identification',
            time: 'Tomorrow Evening'
          });
          router.navigate(`/${appPlatform}/marketplace`, 'back', 'pop');
        }
      },
      {
        text: t('ReminderDialog.button2'),
        handler: () => {
          const currentDate = new Date();
          const sunday = new Date(
            currentDate.setDate(
              currentDate.getDate() - currentDate.getDay() + 7
            )
          );
          const sundayAfternoon = new Date(sunday.setHours(15, 0, 0, 0));
          postReminderFunction(type, sundayAfternoon);
          segmentTrackEvent('User Identification Reminder', {
            flow: 'Identification',
            time: 'Sunday Afternoon'
          });
          router.navigate(`/${appPlatform}/marketplace`, 'back', 'pop');
        }
      },
      {
        text: t('ReminderDialog.button3'),
        role: 'cancel',
        handler: () => {
          segmentTrackEvent('User Identification Reminder', {
            flow: 'Identification',
            time: 'Canceled'
          });
          router.navigate(`/${appPlatform}/marketplace`, 'back', 'pop');
        }
      }
    ]
  });
  return alert.present();
};

/**
 * converts any given phone number in <countryCode> <number>
 * @param phone
 */
export const convertPhoneNumberToFourthLineFormat = (phone: string): string => {
  const parsedPhone = parsePhoneNumber(phone.replace(/^0+/, '+'));
  return `+${parsedPhone.getCountryCode()} ${parsedPhone.getNumber(
    'significant'
  )}`;
};

export const convertStreetToStreetAndNumber = (
  street: string
): { street: string; streetNumber: number } => {
  const splittedByNumber = street.split(/(\d+)/);
  const length = splittedByNumber.length;
  return {
    street: splittedByNumber[0].replace(' ', ''),
    streetNumber: isNaN(Number(splittedByNumber[length - 2]))
      ? 1
      : Number(splittedByNumber[length - 2])
  };
};

/**
 * https://gist.github.com/Jorijn/a2daa199c95c4b04aaf608c3adb42e34
 * @param dataURL
 * @returns
 */
export const convertBase64ToBlob = (dataURL: string): Blob => {
  let i = 0;
  const binary = atob(dataURL.split(',')[1]);
  const array = [];

  while (i < binary.length) {
    array.push(binary.charCodeAt(i));
    i++;
  }
  return new Blob([new Uint8Array(array)], {
    type: dataURL
      .split(',')[0]
      .split(':')[1]
      .split(';')[0]
  });
};

// TODO Also add ipads
// List from https://gist.github.com/adamawolf/3048717
// Remove the additional Data!
const iosModels: Array<{ model: string; name: string }> = [
  { model: 'iPhone1,1', name: 'iPhone' },
  { model: 'iPhone1,2', name: 'iPhone 3G' },
  { model: 'iPhone2,1', name: 'iPhone 3GS' },
  { model: 'iPhone3,1', name: 'iPhone 4' },
  { model: 'iPhone3,2', name: 'iPhone 4' },
  { model: 'iPhone3,3', name: 'iPhone 4' },
  { model: 'iPhone4,1', name: 'iPhone 4S' },
  { model: 'iPhone5,1', name: 'iPhone 5' },
  { model: 'iPhone5,2', name: 'iPhone 5' },
  { model: 'iPhone5,3', name: 'iPhone 5C' },
  { model: 'iPhone5,4', name: 'iPhone 5C' },
  { model: 'iPhone6,1', name: 'iPhone 5S' },
  { model: 'iPhone6,2', name: 'iPhone 5S' },
  { model: 'iPhone7,1', name: 'iPhone 6 Plus' },
  { model: 'iPhone7,2', name: 'iPhone 6' },
  { model: 'iPhone8,1', name: 'iPhone 6s' },
  { model: 'iPhone8,2', name: 'iPhone 6s Plus' },
  { model: 'iPhone8,4', name: 'iPhone SE' },
  { model: 'iPhone9,1', name: 'iPhone 7' },
  { model: 'iPhone9,2', name: 'iPhone 7 Plus' },
  { model: 'iPhone9,3', name: 'iPhone 7' },
  { model: 'iPhone9,4', name: 'iPhone 7 Plus' },
  { model: 'iPhone10,1', name: 'iPhone 8' },
  { model: 'iPhone10,2', name: 'iPhone 8 Plus' },
  { model: 'iPhone10,3', name: 'iPhone X' },
  { model: 'iPhone10,4', name: 'iPhone 8' },
  { model: 'iPhone10,5', name: 'iPhone 8 Plus' },
  { model: 'iPhone10,6', name: 'iPhone X' },
  { model: 'iPhone11,2', name: 'iPhone XS' },
  { model: 'iPhone11,4', name: 'iPhone XS Max' },
  { model: 'iPhone11,6', name: 'iPhone XS Max' },
  { model: 'iPhone11,8', name: 'iPhone XR' },
  { model: 'iPhone12,1', name: 'iPhone 11' },
  { model: 'iPhone12,3', name: 'iPhone 11 Pro' },
  { model: 'iPhone12,5', name: 'iPhone 11 Pro Max' },
  { model: 'iPhone12,8', name: 'iPhone SE 2nd Gen' },
  { model: 'iPhone13,1', name: 'iPhone 12 Mini' },
  { model: 'iPhone13,2', name: 'iPhone 12' },
  { model: 'iPhone13,3', name: 'iPhone 12 Pro' },
  { model: 'iPhone13,4', name: 'iPhone 12 Pro Max' },
  { model: 'iPhone14,2', name: 'iPhone 13 Pro' },
  { model: 'iPhone14,3', name: 'iPhone 13 Pro Max' },
  { model: 'iPhone14,4', name: 'iPhone 13 Mini' },
  { model: 'iPhone14,5', name: 'iPhone 13' },
  { model: 'iPhone14,6', name: 'iPhone SE' },
  { model: 'iPhone14,7', name: 'iPhone 14' },
  { model: 'iPhone14,8', name: 'iPhone 14 Plus' },
  { model: 'iPhone15,2', name: 'iPhone 14 Pro' },
  { model: 'iPhone15,3', name: 'iPhone 14 Pro Max' }
];

export const iosDeviceMapper = (model: string): string => {
  const device = iosModels.find(ios => ios.model === model);
  return device ? device.name : model;
};
