import { ExperienceEnum, ExperienceTransactionAmountEnum, ExperienceTransactionValueEnum } from "@/types/interfaces";

export enum IdentificationComponent {
  Email = 'Email',
  EmailConfirmation = 'EmailConfirmation',
  NamePassword = 'NamePassword',
  Address = 'Address',
  Birthday = 'Birthday',
  Birthplace = 'Birthplace',
  Nationality = 'Nationality',
  ComplianceDocuments = 'ComplianceDocuments',
  PhoneNumber = 'PhoneNumber',
  ContinueOnNative = 'ContinueOnNative',
  ExternalIdentification = 'ExternalIdentification',
  KnowledgeAndExperience = 'KnowledgeAndExperience',
  SecurityAppIntegration = 'SecurityAppIntegration',
  FinishIdentification = 'FinishIdentification',
  BankAccountVerification = 'BankAccountVerification',
  AgreementDocuments = 'AgreementDocuments',
  SuccessScreen = 'SuccessScreen'
}

export enum IdentificationMutations {
  setIdentificationRequiredScreens = 'setIdentificationRequiredScreens',
  setIdentificationActiveScreenIndex = 'setIdentificationActiveScreenIndex',
  setPreviousScreen = 'setPreviousScreen',
  setIdentificationData = 'setIdentificationData',
  setIdentificationInitialState = 'setIdentificationInitialState'
}

export enum DefaultKnowledgeAndExperienceScreens {
  DefaultKnowledgeAndExperienceInfoScreen = 'DefaultKnowledgeAndExperienceInfoScreen',
  FinancialProducts = 'FinancialProducts',
  SpecificFinancialProducts = 'SpecificFinancialProducts',
  InvestmentServices = 'InvestmentServices',
  TransactionsNumber = 'TransactionsNumber',
  InvestmentPerTransaction = 'InvestmentPerTransaction'
}

export enum RealPartKnowledgeAndExperienceScreens {
  KnowledgeAndExperienceInfoScreen = 'KnowledgeAndExperienceInfoScreen',
  BasicQuestions = 'BasicQuestions',
  RiskAppetite = 'RiskAppetite',
  InvestHorizon = 'InvestHorizon',
  InvestmentCapital = 'InvestmentCapital'
}

export interface IdentificationState {
  requiredScreens: Array<IdentificationComponent>;
  activeScreens: Array<IdentificationComponent>;
  activeScreenIndex: number;
  data: IdentificationData;
}

export interface IdentificationData {
  userId?: string;
  email?: string;
  newsletter?: boolean;
  emailVerified?: boolean;
  firstName?: string;
  lastName?: string;
  address?: {
    street?: string;
    city?: string;
    postCode?: string;
    domicile?: string;
  };
  birthDay?: string;
  birthCountry?: string;
  birthCity?: string;
  nationality?: string;
  pep?: boolean;
  phone?: string;
  ibanNumber?: string;
  iban?: string;
  socialSecurityNumber?: string;
  //Default K&E
  investmentFundKnowledge?: boolean;
  governmentBondsKnowledge?: boolean;
  certificateKnowledge?: boolean;
  participationPaperKnowledge?: boolean;
  shareKnowledge?: boolean;
  virtualShareKnowledge?: boolean;
  virtualShareExperience?: ExperienceEnum;
  certificateExperience?: ExperienceEnum;
  participationPaperExperience?: ExperienceEnum;
  shareExperience?: ExperienceEnum;
  governmentBondsExperience?: ExperienceEnum;
  investmentFundExperience?: ExperienceEnum;
  independentServiceExperience?: ExperienceEnum;
  investmentConsultancyServiceExperience?: ExperienceEnum;
  investmentManagementServiceExperience?: ExperienceEnum;
  averageYearlyTransactions?: ExperienceTransactionAmountEnum;
  averageTransactionValue?: ExperienceTransactionValueEnum;
  //Realpart K&E
  basicQuestions?: Array<{label: string; key: string; haveExperience: boolean, haveKnowledge: boolean}>;
  riskAppetite?: string;
  investmentHorizon?: string;
  availableAmount?: number;
}

const getDefaultState = () => {
  return {
    requiredScreens: [] as Array<IdentificationComponent>,
    activeScreens: [] as Array<IdentificationComponent>,
    activeScreenIndex: 0 as number,
    data: {} as IdentificationData,
  }
}

export const identificationModule = {
  state: (): IdentificationState => (getDefaultState()),
  mutations: {
    setIdentificationRequiredScreens(state: IdentificationState, screens: Array<IdentificationComponent>) {
      state.requiredScreens = screens
    },

    setIdentificationActiveScreenIndex(state: IdentificationState, index?: number) {
      if(index === 0) {
        state.activeScreens = []
        state.activeScreenIndex = index
      } else {
        state.activeScreenIndex++
      }

      state.activeScreens.push(state.requiredScreens[state.activeScreenIndex])
    },

    setPreviousScreen(state: IdentificationState) {
      state.activeScreenIndex--
      state.activeScreens.pop()
    },

    setIdentificationData(state: IdentificationState, data: IdentificationData) {
      Object.assign(state.data, data)
    },

    setIdentificationInitialState(state: IdentificationState) {
      Object.assign(state, getDefaultState())
    }
  }
};
