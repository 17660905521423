import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "input-append" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return (_openBlock(), _createBlock(_component_ion_item, {
    mode: 'md',
    class: "inputItem",
    style: _normalizeStyle([_ctx.backgroundStyle, _ctx.isReadOnly ? 'pointer-events: none' : ''])
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_input, {
        mode: 'md',
        ref: _ctx.ref,
        type: _ctx.type,
        value: _ctx.modelValue,
        readonly: _ctx.isReadOnly,
        label: _ctx.label,
        labelPlacement: _ctx.labelPlacement,
        placeholder: _ctx.placeholder,
        autocomplete: _ctx.autocomplete,
        autocapitalize: _ctx.autocapitalize,
        inputmode: _ctx.inputmode,
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value)))
      }, null, 8, ["type", "value", "readonly", "label", "labelPlacement", "placeholder", "autocomplete", "autocapitalize", "inputmode"]),
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "append")
      ])
    ]),
    _: 3
  }, 8, ["style"]))
}