/* eslint-disable @typescript-eslint/interface-name-prefix */

import { getNotifications, markNotificationAsRead } from "@/api/messagingApi";
import { getEncryptedCache, setEncryptedCache } from "../storage/ionicStorage";

export enum MessagingStateMutations {
  setNotifications = "setNotifications",
  setNotificationRead = "setNotificationRead",
}

export enum MessagingStateActions {
  fetchNotifications = 'fetchNotifications',
  fetchInitialNotifications = 'fetchInitialNotifications',
  markNotificationAsRead = 'markNotificationAsRead'
}


export enum TNotificationType {
  forum = 'forum',
  payout = 'payout',
  vat = 'vat',
  text = 'text'
}

export interface INotificationItem {
  id: string;
  userId: string;
  channelId: string;
  title: string;
  text: string;
  sentDate: number;
  seen: boolean;
  action?: string;
  type: TNotificationType;
}

export interface MessagingState {
  lastUpdate: number;
  notifications: INotificationItem[];
  unreadNotifications: number;
  latestPage: number;
}

export const messagingModule = {
  state: (): MessagingState => ({
    lastUpdate: Date.now(),
    notifications: [],
    unreadNotifications: 0,
    latestPage: 0
  }),
  mutations: {
    setNotifications(state: MessagingState, notifications: INotificationItem[], page?: number) {
      if(notifications.length === 0) return
      for(let i=0; i<notifications.length; i++) {
        if(state.notifications.find(item => item.id === notifications[i].id)) {
          return
        } else {
          state.notifications.push(notifications[i])
        }
      }

      state.notifications.sort((a, b) => new Date(b.sentDate).valueOf() - new Date(a.sentDate).valueOf())
      state.unreadNotifications = notifications.filter((n) => !n.seen).length;
      if (page) state.latestPage = page;
      else state.latestPage++;
      state.lastUpdate = Date.now();
    },
    setNotificationRead(state: MessagingState, notificationId: string) {
      const notification = state.notifications.find((n) => n.id === notificationId);
      if (notification) {
        notification.seen = true;
        state.unreadNotifications -= 1;
      }
    }
  },
  actions: {
    async fetchNotifications(context: any) {
      const response = await getNotifications(context.state.latestPage);
      context.commit(MessagingStateMutations.setNotifications, response.data);
    },
    async fetchInitialNotifications(context: any) {
      const cachedNotifications = await getEncryptedCache('cachedNotifications')
      const fetchFromAPI = async () => {
        const response = await getNotifications(0);
        context.commit(MessagingStateMutations.setNotifications, response.data, 0);
        setEncryptedCache('cachedNotifications', JSON.stringify(response.data))
      }
      if (cachedNotifications) {
        const parsedNotifications = JSON.parse(cachedNotifications)
        context.commit(MessagingStateMutations.setNotifications, parsedNotifications);
        fetchFromAPI()
      } else {
        await fetchFromAPI()
      }
    },
    async markNotificationAsRead(context: any, notificationId: string) {
      await markNotificationAsRead(notificationId);
      context.commit(MessagingStateMutations.setNotificationRead, notificationId);
    }
  }
};
