import { createI18n } from 'vue-i18n';
import locales from './langs';
import i18nIsoCode from 'i18n-iso-countries'
import store, { AppPlatform } from '@/store'
import { StateActions } from '@/store';

const numberFormats = {
  en: {
    currency: {
      style: 'currency',
      currency: 'USD',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
  },
  de: {
    currency: {
      style: 'currency',
      currency: 'EUR',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
  },
};

const mapLanguage = (lang: string) => {
  return lang.substring(0, 2);
};

const handleNativeLocale = () => {
  const alpha2Language = process.env.VUE_APP_ENV === 'production' ? process.env.VUE_APP_I18N_LOCALE || 'en' : mapLanguage(navigator.languages[0]) as string
  let alpha3Language = ''

  if(alpha2Language === 'en') {
    alpha3Language = 'eng'
  } else {
    alpha3Language = i18nIsoCode.alpha2ToAlpha3(alpha2Language.toUpperCase())!.toLowerCase()
  }
  store.dispatch(StateActions.setAlpha3Language, alpha3Language)
  return alpha2Language
};

const handleDesktopLocale = () => {
  //For now only german is available
  const alpha2Language = 'de'
  const alpha3Language = 'deu'
  store.dispatch(StateActions.setAlpha3Language, alpha3Language)

  return alpha2Language
};

export default createI18n({
  legacy: false,
  locale: store.state.appPlatform === AppPlatform.app ? handleNativeLocale() : handleDesktopLocale(),
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  globalInjection: true,
  messages: locales,
  numberFormats,
});
