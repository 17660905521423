export interface Config {
  middlewareURL: string;
  baseUrl: string;
}

export const config = {
  middlewareURL: '',
  baseUrl: '',
};

const initConfig = () => {
  console.log('Init config', process.env.VUE_APP_ENV, process.env.VUE_APP_BWPLATFORM);
  const appPlatform = process.env.VUE_APP_BWPLATFORM === 'true'
  switch (process.env.VUE_APP_ENV) {
    case 'production':
      if (appPlatform) {
        config.middlewareURL = 'https://api.bwplatform.app';
      } else {
        config.middlewareURL = 'https://api.app.brickwise.at';
      }
      config.baseUrl = 'https://app.brickwise.at';
      break;
    case 'staging':
    default:
      if (appPlatform) {
        config.middlewareURL = 'https://api.bwplatform.app';
      } else {
        config.middlewareURL = 'https://api.app.brickwise.at';
      }
      config.baseUrl = 'https://app.brickwise.at';
      break;
  }
};

initConfig();
