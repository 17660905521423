import { registerPlugin } from '@capacitor/core';

export interface PDFPlugin {
  viewPDF(options: { url: string }): Promise<void>;
}

const PDFPlugin = registerPlugin<PDFPlugin>('PDFPlugin', {
  web: () => import('../web').then(m => new m.PDFWeb())
});

export default PDFPlugin;
