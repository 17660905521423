import { registerPlugin } from '@capacitor/core';

export interface AdvertisingInfoResponse {
  id: string | null;
  isAdTrackingLimited: boolean;
}

export interface IdfaPlugin {
  getAdvertisingInfo(): Promise<AdvertisingInfoResponse>;
}

const Idfa = registerPlugin<IdfaPlugin>('IdfaPlugin', {
  web: () => import('../web').then(m => new m.IdfaPlugin())
});

export default Idfa;
