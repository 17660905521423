import { config } from '@/helpers/environment.helper';
import axios, { AxiosResponse } from 'axios';

export const centralisedApi = axios.create({
  baseURL: config.middlewareURL,
  withCredentials: true,
});

export const postLogin = async (email: string, password: string) => {
  try {
    const res = await centralisedApi.post('/auth/login', {
      email,
      password,
    });
    return res;
  } catch (error) {
    // @ts-ignore
    return error.response;
  }
};

export const getIchAppLoginUrl = async (register?: boolean): Promise<string | null> => {
  try {
    const url = register ? '/auth/ichApp/url?register=true' : '/auth/ichApp/url'
    const res = await centralisedApi.get(url)
    if (!res || !res.data || !res.data.data || !res.data.data.url) return null
    return encodeURI(res.data.data.url)
  } catch (error) {
    return null
  }
}

export const postIchAppLoginWithCode = async(code: string) => {
  return centralisedApi.post('/auth/ichApp/login', { code })
}

export const getUserSession = async (): Promise<any> => {
  try {
    const session = await centralisedApi.get('/auth/checksession');
    if (session.data && session.data.data.role === 'user') return true;
    return false;
  } catch (err) {
    return false;
  }
};

export const postLogout = async () => {
  return centralisedApi.post('/auth/logout');
};

// export const postImage = (formData: any) => {
//   return centralisedApi.post('/api/v1/data/file', formData)
// }

export const postSupportRequestToCs = async (body: {
  name: string;
  body: string;
  email: string;
}): Promise<void> => {
  try {
    await centralisedApi.post('/api/v1/support/request', body);
  } catch (error) {
    console.log(error);
  }
};

export const postPhoneSupportRequestToCs = (body: {
  date: string;
  time: string;
  body: string;
  phone: string;
  email: string;
  name: string;
}) => {
  return centralisedApi.post('/api/v1/support/phoneRequest', body);
};

export const getSupportQuestionsFromCS = (): Promise<AxiosResponse<any>> => {
  return centralisedApi.get('/api/v1/support/questions');
};

export const postSupportRequest = async (body: {
  name: string;
  body: string;
  email: string;
}): Promise<void> => {
  try {
    await centralisedApi.post('/api/v1/support/request', body);
  } catch (error) {
    console.log(error);
  }
};

export const getEmailAvailability = async (email: string): Promise<boolean> => {
  try {
    const response = await centralisedApi.get(`/auth/email/isAvailable/${email}`);
    return response.data.data.isAvailable;
  } catch (err) {
    return false;
  }
};

export const createAccountWithEmail = async (
  email: string
): Promise<string | null> => {
  try {
    const req = await centralisedApi.post(`/auth/login/otp/${email}`);
    return req.data.data.userId;
  } catch (err) {
    return null;
  }
};

export const validateUserSessionWithToken = async (
  token: string
): Promise<boolean> => {
  try {
    await centralisedApi.post('/auth/login/otp', { token });
    return true;
  } catch (err) {
    return false;
  }
};

export const setPasswordWithValidSession = async (
  passwordNew: string
): Promise<boolean> => {
  try {
    await centralisedApi.patch('/auth/set-password', { passwordNew });
    return true;
  } catch (err) {
    return false;
  }
};

export const postImage = (formData: any) => {
  return centralisedApi.post('/api/v1/data/file', formData)
}
