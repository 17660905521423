export enum NativeAssetScreen {
  AssetDetails = 'AssetDetails',
  AssetInfo = 'AssetInfo',
  LocationDetails = 'LocationDetails',
  InvestmentDetails = 'InvestmentDetails',
  RentDetails = 'RentDetails',
  AssetRecords = 'AssetRecords'
}

export enum NativeAssetMutations {
  setActiveScreen = 'setActiveScreen'
}

export interface NativeAssetState {
  activeScreen: NativeAssetScreen;
}

export const nativeAssetModule = {
  state: (): NativeAssetState => ({
    activeScreen: NativeAssetScreen.AssetDetails
  }),
  mutations: {
    setActiveScreen(state: NativeAssetState, activeScreen: NativeAssetScreen) {
      state.activeScreen = activeScreen
    },
  },
};
