function initialState() {
  return {
    register: {
      userId: '',
      email: '',
      newsletter: false,
      firstName: '',
      lastName: '',
      country: '',
      countryCode: '',
      password: '',
      resendToken: '',
      acceptedRisk: false,
      referralCode: '',
      affiliatePartnerId: ''
    },
    identity: {
      citizenship: '',
      citizenshipCountryCode: '',
      birthCountry: '',
      birthCountryCode: '',
      birthCity: '',
      birthdayDay: '',
      birthdayMonth: '',
      birthdayYear: '',
      street: '',
      houseNumber: '',
      postcode: '',
      city: '',
      identificationDocument: '',
      pep: false,
      iban: '',
      usaTaxes: '',
      stageComplete: false,
      uploadSent: false,
    },
    phone: {
      callCode: '',
      number: '',
    },
    experience: {
      knowledgeItems: [],
      experienceItems: [],
      brokerItems: [],
      transactionItems: [],
    },
  };
}

export enum RegisterUserStateMutations {
  setRegisterUserRegister = 'setRegisterUserRegister',
  setRegisterUserIdentity = 'setRegisterUserIdentity',
  setRegisterUserPhone = 'setRegisterUserPhone',
  setExperienceUserRegister = 'setExperienceUserRegister',
  resetState = 'resetState',
}
export interface Register {
  userId: string | null;
  email: string;
  newsletter: boolean;
  firstName: string;
  lastName: string;
  country: string;
  countryCode: string;
  password: string;
  resendToken: string;
  acceptedRisk: boolean;
  referralCode: string;
  affiliatePartnerId: string;
}

export interface Identity {
  citizenship: string;
  citizenshipCountryCode: string;
  birthCountry: string;
  birthCountryCode: string;
  birthCity: string;
  birthdayDay: string;
  birthdayMonth: string;
  birthdayYear: string;
  street: string;
  houseNumber: string;
  postcode: string;
  city: string;
  identificationDocument: string;
  pep: boolean;
  iban: string;
  usaTaxes: string;
  stageComplete: boolean;
  uploadSent: boolean;
}

export interface Phone {
  callCode: string;
  number: string;
}
export interface RegisterUser {
  register: Register;
  identity: Identity;
  phone: Phone;
  //TODO need to refactor
  experience: {
    // @ts-ignore
    knowledgeItems: array;
    // @ts-ignore
    experienceItems: array;
    // @ts-ignore
    brokerItems: array;
    // @ts-ignore
    transactionItems: array;
  };
}

export interface RegisterUserState {
  data: RegisterUser;
}

const registerUser = initialState();

export const registerUserModule = {
  state: (): RegisterUserState => ({
    // @ts-ignore
    data: registerUser,
  }),
  mutations: {
    setRegisterUserRegister(state: RegisterUserState, user: object) {
      Object.keys(state.data.register).forEach((key: any) => {
        // @ts-ignore
        if (user[key]) {
          // @ts-ignore
          state.data.register[key] = user[key];
        }
      });
    },

    setRegisterUserIdentity(state: RegisterUserState, user: object) {
      Object.keys(state.data.identity).forEach((key) => {
        //@ts-ignore
        if (user[key]) {
          //@ts-ignore
          state.data.identity[key] = user[key];
        }
      });
    },

    setRegisterUserPhone(state: RegisterUserState, user: object) {
      Object.keys(state.data.phone).forEach((key) => {
        //@ts-ignore
        if (user[key]) {
          //@ts-ignore
          state.data.phone[key] = user[key];
        }
      });
    },

    resetState(state: RegisterUserState) {
      const defaultState = initialState();
      Object.keys(state.data).forEach((key: any) => {
        // @ts-ignore
        state.data[key] = defaultState[key];
      });
    },

    setExperienceUserRegister(state: RegisterUserState, user: object) {
      Object.keys(state.data.experience).forEach((key) => {
        // @ts-ignore
        if (user[key]) {
          // @ts-ignore
          state.data.experience[key] = user[key];
        }
      });
    },
  },
  actions: {
    //
  },
};
