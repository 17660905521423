import { config } from '@/helpers/environment.helper';
import { INotificationItem } from '@/store/modules/messaging';
import axios from 'axios';

export const messagingApi = axios.create({
  baseURL: config.middlewareURL,
  timeout: 100000,
  withCredentials: true,
});

export const getNotifications = async (page: number): Promise<{
  data: INotificationItem[];
  error?: string;
}> => {
  const response = await messagingApi.get(`/api/v1/notification?page=${page}&pageSize=20`);
  return response.data;
};

export const markNotificationAsRead = async (notificationId: string): Promise<void> => {
  return messagingApi.post(`/api/v1/notification/${notificationId}/seen`);
}

export const postSubscribeChannel = (channelId: string): Promise<void> => {
  return messagingApi.post('/api/v1/channel/subscribe/' + channelId)
}

export const postUnsubscribeChannel = (channelId: string): Promise<void> => {
  return messagingApi.post('/api/v1/channel/unsubscribe/' + channelId)
}

export const getIsSubscribed = (channelId: string): Promise<{ error: string; data: { data: { isSubscribed: boolean } }}> => {
  return messagingApi.get('/api/v1/channel/subscribe/' + channelId)
}