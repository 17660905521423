import { useModalData } from '@/helpers/ModalBackgroundHelper';
/* eslint-disable @typescript-eslint/camelcase */
import { createStore } from 'vuex';
import { assetsModule } from '@/store/modules/assets'
import { AssetStateInterface } from "@/types/v2/asset/interfaces"
import { userModule, UserState } from './modules/user';
import { registerUserModule, RegisterUserState } from './modules/registerUser';
import { identificationModule, IdentificationState } from '@/store/modules/identification'
import { registrationModule, RegistrationState } from './modules/registration'
import {
  secondaryMarketModule,
  SecondaryMarketState
} from './modules/secondaryMarket';
import { persistedState } from './storage/encryptedLocalStore';
import { messagingModule, MessagingState } from './modules/messaging';
import { IllustrationsInterface, LastUserInterface, SafeAreaInterface, AppColorsInterface, UserActivationModulesInterface, RegistrationDocumentsInterface } from '@/types/interfaces';
import { initPlatformPartnerId } from '@/helpers/GlobalFunctions';
import { launchpadApi } from '@/api/stoApi';
import { centralisedApi } from '@/api/centralisedApi'
import { messagingApi } from '@/api/messagingApi';
import { middlewareApi } from '@/api/middlewareApi';
import { nativeAssetModule, NativeAssetState } from './modules/nativeAsset';
import { infoModalModule, InfoModalState } from './modules/infoModal'

export enum StateActions {
  loadSafeAreaInsets = 'loadSafeAreaInsets',
  setAlpha3Language = 'setAlpha3Language'
}

export enum StateMutations {
  setAppConfig = 'setAppConfig',
  setAppInfo = 'setAppInfo',
  setIsAppActive = 'setIsAppActive',
  setAppPlatform = 'setAppPlatform',
  setAppVersion = 'setAppVersion',
  setIntroSlidesWatched = 'setIntroSlidesWatched',
  setAppOpenedFirstTime = 'setAppOpenedFirstTime',
  setLoadingSpinner = 'setLoadingSpinner',
  setModalBackground = 'setModalBackground',
  setLoggedIn = 'setLoggedIn',
  setAlpha3Language = 'setAlpha3Language',
  setBiometricsType = 'setBiometricsType',
  setBiometricsEnabled = 'setBiometricsEnabled',
  setAskedForBiometrics = 'setAskedForBiometrics',
  temporaryPasswordSafe = 'temporaryPasswordSafe',
  setTemporaryTokenSafe = 'setTemporaryTokenSafe',
  setTemporaryUrlSafe = 'setTemporaryUrlSafe',
  setPushEnabled = 'setPushEnabled',
  setAskedForPush = 'setAskedForPush',
  setPushToken = 'setPushToken',
  setFirebaseId = 'setFirebaseId',
  setLastUser = 'setLastUser',
  setSocketId = 'setSocketId',
  setAssetUpdate = 'setAssetUpdate',
  setDefaultFee = 'setDefaultFee',
  setFeatureFlags = 'setFeatureFlags',
  setKeyboardIsActive = 'setKeyboardIsActive',
  setDeviceInformation = 'setDeviceInformation',
  setSafeAreaInsets = 'setSafeAreaInsets',
  setLastTimesWhereUserAskedForRating = 'setLastTimesWhereUserAskedForRating',
  setLastTimesWhereUserAskedForMarketingNewsletter = 'setLastTimesWhereUserAskedForMarketingNewsletter',
  setInstallParameter = 'setInstallParameter'
}

export interface StoreInterface {
  state: StateInterface;
  getters: {
    getFetchedAssets: Function;
    getAssets: any;
    getAssetById: any;
    getUser: Function;
    getAppConfig: Function;
    getAppPlatform: Function;
  };
  commit: any;
  dispatch: any;
}

export interface FeatureFlags {
  referral: boolean;
  liking: boolean;
  easter: boolean;
  bonusCodes: boolean;
  partialEmission: boolean;
  enableChart: boolean;
  marketingConsent: boolean;
}

export interface InstallParameters {
  utm_id: string;
  utm_source: string;
  utm_medium: string;
  utm_campaign: string;
  utm_term: string;
  utm_content: string;
  webGoogleClickId: string;
  webFacebookClickId: string;
  webGoogleClientId: string;
  webFacebookId: string;
}

export interface FeatureFlagsInterface {
  blogArticleMarketplace: boolean;
  introSlidesSignUp: boolean;
  minimumSubscriptionAmount: number;
  partnerSelectionPage: boolean;
  referralProgram: boolean;
  startBonus: number;
  yieldCalculatorMarketplace: boolean;
  ichAppLogin: boolean;
  likeButton: boolean;
  manualPayment: boolean;
}

export interface AppConfigReferralInterface {
  termsURL: string;
  referrerAmount: string;
}

export enum CommunicationTypeEnum {
  direct = 'direct',
  indirect = 'indirect'
}

export enum AppPlatform {
  app = 'app',
  mobileWeb = 'mobileWeb',
  desktopWeb = 'desktopWeb',
}

export interface AppConfigInterface {
  appIdentifier: string;
  colors: AppColorsInterface;
  communicationType: CommunicationTypeEnum;
  featureFlags: FeatureFlagsInterface;
  userActivationModules: UserActivationModulesInterface;
  registrationDocuments: Array<RegistrationDocumentsInterface>;
  hasBonusCode: boolean;
  assetFundingProgressBar: boolean;
  imprintDetails: string;
  riskInfo: string;
  hasMap: boolean;
  font: string;
  logo: string;
  logoBig: string;
  mapIcon: string;
  partnerId: string;
  partnerLegalName: string;
  partnerShortName: string;
  illustration: IllustrationsInterface;
  supportEmail: string;
  localAppURL: string;
  appIcon: string;
  appURL: string;
  domainUriPrefix: string;
  supportURL: string;
  appStoreId: string;
  appIdentifierAndroid: string;
  appIdentifierIos: string;
  firebaseApiKey: string;
  referral: AppConfigReferralInterface;
  introSlides: Array<IntroSlidesInterface>;
  operatorInfo: string;
  marketingMessage: string;
  providerInfo: string;
  termsAndConditionsIntroHeading: string;
  termsAndConditionsIntroSubtext: string;
  marketplaceAssetCardShowTags: boolean;
  enableCompanyRegistration: boolean;
  UStForPayouts: boolean;
  privacy: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface IntroSlidesInterface {
  title: string;
  url: string;
}

export interface AppInfoInterface {
  name: string;
  id: string;
  build: string;
  version: string;
}

interface StateInterface {
  appConfig: AppConfigInterface;
  appInfo: AppInfoInterface;
  assets: AssetStateInterface;
  nativeAsset: NativeAssetState;
  infoModal: InfoModalState;
  identification: IdentificationState;
  registration: RegistrationState;
  user: UserState;
  messaging: MessagingState;
  registerUser: RegisterUserState;
  secondaryMarket: SecondaryMarketState;
  isAppActive: boolean;
  appPlatform: AppPlatform;
  storeVersion: number;
  appVersion: string;
  introSlidesWatched: boolean;
  appOpenedFirstTime: boolean;
  isLoadingSpinner: boolean;
  isModalBackground: boolean;
  loggedIn: boolean;
  alpha3Language: string;
  biometricsType: string;
  biometricsEnabled: boolean;
  askedForBiometrics: string;
  temporaryPasswordSafe: string;
  temporaryTokenSafe: string;
  temporaryUrlSafe: string;
  pushEnabled: boolean;
  askedForPush: Array<string>;
  pushToken: string;
  firebaseId: string;
  socketId: string;
  updatedAsset: string;
  defaultFee: number;
  lastUser: LastUserInterface;
  featureFlags: FeatureFlags;
  keyboardIsActive: boolean;
  deviceInformation: {
    model: string;
    type: string;
    osVersion: string;
    manufacturer: string;
  };
  safeAreaInsets: SafeAreaInterface;
  lastTimesWhereUserAskedForRating: string[];
  lastTimeWhereUserAskedForMarketingNewsletter: Date;
  installParameters: InstallParameters;
}

const vueStore = createStore({
  plugins: [persistedState],
  modules: {
    assets: assetsModule,
    nativeAsset: nativeAssetModule,
    user: userModule,
    registerUser: registerUserModule,
    secondaryMarket: secondaryMarketModule,
    messaging: messagingModule,
    infoModal: infoModalModule,
    identification: identificationModule,
    registration: registrationModule
  },
  state(): StateInterface {
    return {
      appConfig: {} as AppConfigInterface,
      appInfo: {} as AppInfoInterface,
      isAppActive: false,
      appPlatform: '' as AppPlatform,
      storeVersion: 1,
      appVersion: '0',
      introSlidesWatched: false,
      appOpenedFirstTime: false,
      isLoadingSpinner: false,
      isModalBackground: false,
      loggedIn: false,
      alpha3Language: '',
      biometricsType: 'null',
      biometricsEnabled: false,
      askedForBiometrics: '',
      temporaryPasswordSafe: '',
      temporaryTokenSafe: '',
      temporaryUrlSafe: '',
      pushEnabled: false,
      askedForPush: [''],
      pushToken: '',
      firebaseId: '',
      socketId: '',
      updatedAsset: '',
      defaultFee: 0,
      lastUser: {
        email: ''
      },
      featureFlags: {
        referral: false,
        liking: false,
        easter: false,
        bonusCodes: false,
        partialEmission: false,
        enableChart: false,
        marketingConsent: false,
      },
      keyboardIsActive: false,
      deviceInformation: {
        model: '',
        type: '',
        osVersion: '',
        manufacturer: ''
      },
      safeAreaInsets: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0
      },
      lastTimesWhereUserAskedForRating: new Array<string>(),
      lastTimeWhereUserAskedForMarketingNewsletter: new Date(),
      installParameters: {
        utm_id: '',
        utm_source: '',
        utm_medium: '',
        utm_campaign: '',
        utm_term: '',
        utm_content: '',
        webGoogleClickId: '',
        webFacebookClickId: '',
        webGoogleClientId: '',
        webFacebookId: ''
      }
    } as StateInterface;
  },
  actions: {
    loadSafeAreaInsets(context: any, safeAreaInsets: SafeAreaInterface) {
      context.commit(StateMutations.setSafeAreaInsets, safeAreaInsets)
    },
    setAlpha3Language(context: any, newValue: string) {
      context.commit(StateMutations.setAlpha3Language, newValue);
    },
  },
  mutations: {
    setAppConfig(state: StateInterface, config: AppConfigInterface) {
      state.appConfig = config
      const instances = [middlewareApi, centralisedApi, messagingApi, launchpadApi]
      initPlatformPartnerId(state.appConfig.partnerId, instances)
    },
    setAppInfo(state: StateInterface, appInfo: AppInfoInterface) {
      state.appInfo = appInfo
    },
    setAppPlatform(state: StateInterface, platform: AppPlatform) {
      state.appPlatform = platform;
    },
    setIsAppActive(state: StateInterface, active: boolean) {
      state.isAppActive = active;
    },
    setAppVersion(state: StateInterface, appVersion: string) {
      state.appVersion = appVersion;
    },
    setIntroSlidesWatched(state: StateInterface, newValue: boolean) {
      state.introSlidesWatched = newValue;
    },
    setAppOpenedFirstTime(state: StateInterface) {
      state.appOpenedFirstTime = true;
    },
    setLoadingSpinner(state: StateInterface, newValue: boolean) {
      state.isLoadingSpinner = newValue;
    },
    setModalBackground(state: StateInterface, newValue: boolean) {
      if(newValue) {
        useModalData().modalData.value.backgroundOpacity = 0.956
      }
      state.isModalBackground = newValue;
    },
    setLoggedIn(state: StateInterface, newValue: boolean) {
      state.loggedIn = newValue;
    },
    setAlpha3Language(state: StateInterface, newValue: string) {
      state.alpha3Language = newValue;
    },
    setBiometricsType(state: StateInterface, newValue: string) {
      state.biometricsType = newValue;
    },
    setBiometricsEnabled(state: StateInterface, newValue: boolean) {
      state.biometricsEnabled = newValue;
    },
    setAskedForBiometrics(state: StateInterface, newValue: string) {
      state.askedForBiometrics = newValue;
    },
    temporaryPasswordSafe(state: StateInterface, newValue: string) {
      state.temporaryPasswordSafe = newValue;
    },
    setTemporaryTokenSafe(state: StateInterface, newValue: string) {
      state.temporaryTokenSafe = newValue;
    },
    setTemporaryUrlSafe(state: StateInterface, newValue: string) {
      state.temporaryUrlSafe = newValue;
    },
    setPushEnabled(state: StateInterface, newValue: boolean) {
      state.pushEnabled = newValue;
    },
    setAskedForPush(state: StateInterface, newValue: string) {
      state.askedForPush.push(newValue.toString());
    },
    setPushToken(state: StateInterface, newValue: string) {
      state.pushToken = newValue;
    },
    setFirebaseId(state: StateInterface, newValue: string) {
      state.firebaseId = newValue;
    },
    setLastUser(state: StateInterface, user: LastUserInterface) {
      state.lastUser = user;
    },
    setSocketId(state: StateInterface, socketId: string) {
      console.log(socketId);
      state.socketId = socketId;
    },
    setAssetUpdate(state: StateInterface, assetId: string) {
      state.updatedAsset = assetId;
    },
    setDefaultFee(state: StateInterface, fee: number) {
      state.defaultFee = fee;
    },
    setFeatureFlags(state: StateInterface, featureFlag: object) {
      Object.keys(state.featureFlags).forEach((key: any) => {
        // @ts-ignore
        if (featureFlag[key]) {
          // @ts-ignore
          state.featureFlags[key] = featureFlag[key];
        }
      });
    },

    setKeyboardIsActive(state: StateInterface, isActive: boolean) {
      state.keyboardIsActive = isActive;
    },

    setSafeAreaInsets(state: StateInterface, safeAreaInsets: SafeAreaInterface) {
      state.safeAreaInsets = safeAreaInsets;
    },

    setDeviceInformation(
      state: StateInterface,
      deviceInformation: {
        model: string;
        type: string;
        osVersion: string;
        manufacturer: string;
      }
    ) {
      state.deviceInformation = deviceInformation;
    },

    setLastTimesWhereUserAskedForRating(
      state: StateInterface,
      newValue: string[]
    ) {
      state.lastTimesWhereUserAskedForRating = newValue;
    },

    setLastTimesWhereUserAskedForMarketingNewsletter(state: StateInterface, newValue: Date) {
      state.lastTimeWhereUserAskedForMarketingNewsletter = newValue;
    },

    setInstallParameter(state: StateInterface, installParameters: object) {
      Object.keys(state.installParameters).forEach((key: any) => {
        // @ts-ignore
        if (installParameters[key]) {
          // @ts-ignore
          state.installParameters[key] = installParameters[key];
        }
      });
    }
  },
  getters: {
    getSocketData(state: StateInterface) {
      return { email: state.user.data.email, socketId: state.socketId };
    },

    getAppConfig(state: StateInterface) {
      return state.appConfig
    },
    getAppPlatform(state: StateInterface) {
      return state.appPlatform
    }
  }
});

//Testing purpose
//@ts-ignore
if (window.Cypress) {
  //@ts-ignore
  window.__store__ = vueStore
}

export default vueStore;
