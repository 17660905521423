import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Teleport as _Teleport, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "nativeInfoModal py-24 px-16" }
const _hoisted_2 = { class: "nativeInfoModalContainer" }
const _hoisted_3 = { class: "title titleMedium mb-16" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  key: 0,
  class: "inputsContainer mt-16"
}
const _hoisted_6 = {
  key: 1,
  class: "buttonsContainer mt-32"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppInput = _resolveComponent("AppInput")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_SheetModal = _resolveComponent("SheetModal")!

  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createVNode(_component_SheetModal, {
      isModalOpen: _ctx.displayNativeModal,
      onCloseClick: _ctx.closeInfoModal
    }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.infoData.header), 1),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.infoData.descriptions, (item, i) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "text-holder",
                key: i
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(["text paragraphExtraSmall mb-8", { underlined : item.link }]),
                  onClick: ($event: any) => (item.link ? _ctx.openPdf(item.link) : '')
                }, _toDisplayString(item.text), 11, _hoisted_4)
              ]))
            }), 128)),
            (_ctx.infoData.inputs && _ctx.infoData.inputs.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.infoData.inputs, (item, i) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "inputItem",
                      key: i
                    }, [
                      _createVNode(_component_AppInput, {
                        modelValue: item.value,
                        "onUpdate:modelValue": ($event: any) => ((item.value) = $event),
                        label: item.label,
                        type: item.type
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "label", "type"])
                    ]))
                  }), 128))
                ]))
              : _createCommentVNode("", true),
            (_ctx.infoData.buttons && _ctx.infoData.buttons.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.infoData.buttons, (item, i) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "buttonItem",
                      key: i
                    }, [
                      _createVNode(_component_AppButton, {
                        type: item.type,
                        onClick: ($event: any) => (item.function ? item.function() : '')
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.text), 1)
                        ]),
                        _: 2
                      }, 1032, ["type", "onClick"])
                    ]))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _: 1
    }, 8, ["isModalOpen", "onCloseClick"])
  ]))
}