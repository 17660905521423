import createPersistedState from 'vuex-persistedstate';
import SecureLS from 'secure-ls';
import { isPlatform } from '@ionic/vue';

let store: null | SecureLS;

// Secure Storage currently not working? Can't set data as far as I checked it

const initStore = async () => {
  if (isPlatform('capacitor'))
    store = new SecureLS({
      encodingType: 'aes',
      encryptionSecret:
        'bccb32c96e64b6eed5f3179b27bd9e7d364e9dc7f3e7fe6296082a83dfb7ec94'
    });
  // await SecureStorageEcho.create('store');
  else
    store = new SecureLS({
      encodingType: 'aes',
      encryptionSecret:
        'bccb32c96e64b6eed5f3179b27bd9e7d364e9dc7f3e7fe6296082a83dfb7ec94'
    });
};

initStore();

export const persistedState = createPersistedState({
  paths: [
    'appConfig',
    'introSlidesWatched',
    'appOpenedFirstTime',
    'biometricsEnabled',
    'askedForBiometrics',
    'pushEnabled',
    'askedForPush',
    'socketId',
    'lastUser',
    'registerUser',
    'registration.data',
    'lastTimesWhereUserAskedForRating',
    'lastTimeWhereUserAskedForMarketingNewsletter',
    'installParameters'
  ],
  storage: {
    getItem: key => (store ? store.get(key) : null),
    setItem: (key, value) => (store ? store.set(key, value) : null),
    removeItem: key => (store ? store.remove(key) : null)
  },
  key: 'brickwise-store'
});

export { store };
