import { TransactionMethods, TransactionStatuses, TransactionTypes } from '@/types/enums'
import { TransactionStatus } from '@/store/modules/user';
import { TranslateResult } from 'vue-i18n';
import { Component } from 'vue';
import { AssetOfferStatus, AssetOfferType } from './v2/asset/enums';
import { TermsTypes } from '@/types/enums';

export interface SafeAreaInterface {
  top: number;
  bottom: number;
  left: number;
  right: number;
}
export interface CountryInterface {
  id: number;
  name: string;
  alpha2: string;
  alpha3: string;
  callCode: string;
}

export interface QuestionInterface {
  _id: string;
  id: string;
  zendeskId: string;
  locales: string;
  title: string;
  body: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface CurrentUserAsset {
  averageSharePrice: number;
  id: string;
  shares: number;
  stage: string;
  transactionAmount: number;
  _id: string;
}

export interface TransactionInterface {
  id: string;
  title?: string;
  type: TransactionTypes;
  method: TransactionMethods;
  shares?: number;
  status: TransactionStatuses;
  paymentInformation: string;
  amount: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface AssetsMapMarkers {
  lat?: number|null;
  lng?: number|null;
  id?: string|null;
  title?: string|null;
}

// export enum IDFA_STATUS {
//   TRACKING_PERMISSION_NOT_DETERMINED,
//   TRACKING_PERMISSION_RESTRICTED,
//   TRACKING_PERMISSION_DENIED,
//   TRACKING_PERMISSION_AUTHORIZED
// }
// export interface IdfaResponse {
//   idfa: string;
//   trackingLimited: boolean;
//   trackingPermission: IDFA_STATUS;
// }

export enum BonusType {
  primaryMarket = 'primaryMarket',
  secondaryMarket = 'secondaryMarket',
  primaryAndSecondary = 'primaryAndSecondary',
  referral = 'referral',
}
export interface UserBonus {
  id: string;
  type: BonusType | null;
  information: string;
  message: string;
  amount: number;
  assetId?: string;
  minimumNumberOfShares?: number;
  minimumVolume?: number;
}

export enum DynamicLinkTypes {
  affiliate = 'affiliate',
  referral = 'referral',
  startBonus = 'startBonus',
  appStoreRating = 'appStoreRating',
}

export enum NotificationStatus {
  Nothing = 'Nothing',
  Register = 'Register',
  Identification = 'Identification',
  SecurityApp = 'SecurityApp',
  WaitingForVerification = 'WaitingForVerification',
  Bonus = 'Bonus',
}

export enum IdentificationMethodEnum {
  IDNow = 'IDNow',
  Fourthline = 'Fourthline',
}

export enum FourthLineIdentificationStatus {
  new = 'new',
  pending = 'pending',
  manualCheck = 'manualCheck',
  bavRequired = 'bavRequired',
  kycRequired = 'kycRequired',
  qesRequired = 'qesRequired',
  success = 'success',
  rejected = 'rejected', // because of fraud or risk reasons
}

export enum FourthLineVerificationStatus {
  new = 'new',
  pending = 'pending',
  invalidData = 'invalid_data',
  inconsistentData = 'inconsistent_data',
  success = 'success',
  rejected = 'rejected',
  fraud = 'fraud',
}

export enum FourthLineSignatureStatus {
  new = 'new', // after the request
  pending = 'pending', // the signature is currently checked by fourthLine
  selfieRequired = 'selfie_required', // the signature was too long ago
  kycRequired = 'kyc_required', // check the status_code
  userConsentRequired = 'user_consent_required', // start the signature process in the app
  confirmationRequired = 'confirmation_required', // SMS code in the app
  signed = 'signed', // all done
  rejected = 'rejected',
  error = 'error',
}

export interface FourthLineFrontendIdentification {
  status: FourthLineIdentificationStatus;
  verificationStatus?: FourthLineVerificationStatus;
  verificationStatusFeedback?: Array<{
    code: number;
    information: string;
  }>;
  signatureStatus?: FourthLineSignatureStatus;
  signatureFeedback?: string;
}

export interface KnowledgeInfoItem {
  text: TranslateResult | string;
  value: string;
  selected: boolean;
}

export enum ExperienceEnum {
  noExperience = 'noExperience',
  lowerThan3 = 'lower than 3 years',
  threeTo5 = '3 to 5 years',
  moreThan5 = 'greater than 5 years',
}

export enum ExperienceTransactionAmountEnum {
  none = '0',
  upToFive = '5',
  upToTen = '10',
  moreThanTen = '11',
}

export enum ExperienceTransactionValueEnum {
  upTo3K = '3000',
  upTo5K = '5000',
  upTo10K = '10000',
  moreThan10K = '10001',
}

export enum TransactionTaxTypeEnum {
  KEST = 'KESt',
  KapESt = 'KapESt',
  Soli = 'Soli',
  Kirchensteuer = 'Kirchensteuer',
}

export interface PayOutAccounts {
  id: string;
  userId: string;
  IBAN: string;
  mangopayPayoutAccountId: string;
  isActive: boolean;
}

export enum ComplianceAction {
  Accepted5KLimit = 'Accepted5KLimit',
  Accepted20PercentDifference = 'Accepted20PercentDifference',
}

export interface BioError {
  message: string;
  code: number;
}

export interface ListItemPros {
  text: string;
  src: Component;
}

export interface InvestmentOpenOffer {
  id: string;
  type: AssetOfferType;
  title: string;
  assetId: string;
  status: AssetOfferStatus | TransactionStatus;
  amount: number;
  price: number;
  createdAt: Date;
  userNeedsToDoAction: boolean;
  address: string;
}

export interface AssetMenuInterface {
  src?: string;
  displayName: string;
  routeName: string;
  icon?: Component;
  isDisplayed?: boolean;
  isActive?: boolean;
}

export interface TransactionTaxesInterface {
  amount: number;
  percentage: number;
  type: TransactionTaxTypeEnum;
  _id: string;
}

export interface TransactionInfoInterface {
  fiscalMonth: number;
  fiscalYear: number;
  taxOffice: string;
}

export interface LastUserInterface {
  email: string;
}

export interface IllustrationsInterface {
  attentionWarning: string;
  notificationBell: string;
  bellCheckMark: string;
  chart: string;
  checklist: string;
  checkMark: string;
  creditCards: string;
  documentMagnidyingGlass: string;
  email: string;
  faceId: string;
  giftBox: string;
  hourGlass: string;
  identityShield: string;
  identityWarning: string;
  mapLocation: string;
  selfie: string;
  studentHat: string;
  touchId: string;
  userCheckMark: string;
  phoneDownload: string;
  dinosaur: string;
  lockLink: string;
  chartHouse: string;
  euro: string;
  housePlus: string;
  walletArrowLeft: string;
  walletArrowRight: string;
}

export interface SpvMarketingResponseInterface {
  marketingTypes: MarketingTypesInterface;
  dataTransferLegalEntity: string;
}

export interface MarketingTypesInterface {
  messages: boolean;
  phone: boolean;
  profiling: boolean;
  dataTransfer: boolean;
}

export interface SpvMarketingRequestInterface {
  messages: {
    enabled: boolean;
    timestamp: Date;
  };
  phone: {
    enabled: boolean;
    timestamp: Date;
  };
  profiling: {
    enabled: boolean;
    timestamp: Date;
  };
  dataTransfer: {
    enabled: boolean;
    timestamp: Date;
  };
}

export interface ShareLinkInterface {
  domainUriPrefix: string;
  uri: string;
  androidParameters: {
    packageName: string;
  };
  iosParameters: {
    bundleId: string;
    appStoreId: string;
  };
}

export interface FooterDocumentsInterface {
  label: string;
  url: string;
  isAvailable: boolean;
}

export interface AppColorsInterface {
  brand: BrandColorsInterface;
  primary: PrimaryColorsInterface;
  secondary: SecondaryColorsInterface;
  system: SystemColorsInterface;
  highlight: HighlightColorsInterface;
  navyShades: NavyShadesColorsInterface;
  CTAColors: CTAColorsInterface;
}

export interface BrandColorsInterface {
  brand500: string;
  brand400: string;
  brand300: string;
  brand200: string;
  brand100: string;
}

export interface PrimaryColorsInterface {
  primary500: string;
  primary400: string;
  primary300: string;
  primary200: string;
  primary100: string;
}

export interface SecondaryColorsInterface {
  secondary500: string;
  secondary400: string;
  secondary300: string;
  secondary200: string;
  secondary100: string;
}

export interface SystemColorsInterface {
  success500: string;
  success200: string;
  error500: string;
  error200: string;
}

export interface HighlightColorsInterface {
  highlight500: string;
  highlight400: string;
  highlight300: string;
  highlight200: string;
  highlight100: string;
}

export interface NavyShadesColorsInterface {
  navyShades900: string;
  navyShades800: string;
  navyShades700: string;
  navyShades600: string;
  navyShades500: string;
  navyShades400: string;
  navyShades300: string;
  navyShades200: string;
  navyShades100: string;
  white: string;
}

export interface CTAColorsInterface {
  primaryCTALabelColor: string;
}

export interface UserActivationPersonalInformationInterface {
  userId: boolean;
  email: boolean;
  phone: boolean;
  status: boolean;
  firstName: boolean;
  lastName: boolean;
  personalInformation: boolean;
  birthDate: boolean;
  street: boolean;
  PLZ: boolean;
  city: boolean;
  domicile: boolean;
  birthCountry: boolean;
  birthCity: boolean;
  nationality: boolean;
  socialSecurityNumber: boolean;
  iban: boolean;
}

export interface UserActivationModulesInterface {
  verifiedEmail: boolean;
  identification: boolean;
  personalInformation: UserActivationPersonalInformationInterface;
  securityApp: boolean;
  knowledgeAndExperience: string;
  termsAndConditions: Array<TermsTypes>;
  compliance: boolean;
}

export interface RegistrationDocumentsInterface {
  name: string;
  alignment: string;
  destination: string;
  template: {
    address: string;
    type: string
  }
}

export interface UserActivationTermsAndConditionsInterface {
  brickwiseTermsTimestamp?: string;
  brickwiseCustomerInformationTimestamp?: string;
  fourthlineTermsTimestamp?: string;
  effectaTermsTimestamp?: string;
  mangopayTermsTimestamp?: string;
  termsIpAddress?: string;
  realpartTermsTimestamp?: string;
  realpartPrivacyTermsTimestamp?: string;
}
