export enum RegistrationComponent {
  Email = 'Email',
  EmailConfirmation = 'EmailConfirmation',
  NamePassword = 'NamePassword'
}

export enum RegistrationMutations {
  setRegistrationRequiredScreens = 'setRegistrationRequiredScreens',
  setRegistrationActiveScreenIndex = 'setRegistrationActiveScreenIndex',
  setRegistrationData = 'setRegistrationData',
  setRegistrationInitialState = 'setRegistrationInitialState'
}

export interface RegistrationState {
  requiredScreens: Array<RegistrationComponent>;
  activeScreens: Array<RegistrationComponent>;
  activeScreenIndex: number;
  data: RegistrationData;
}

export interface RegistrationData {
  userId?: string;
  email?: string;
  newsletter?: boolean;
  emailVerified?: boolean;
  firstName?: string;
  lastName?: string;
}

const getDefaultState = () => {
  return {
    requiredScreens: [] as Array<RegistrationComponent>,
    activeScreens: [] as Array<RegistrationComponent>,
    activeScreenIndex: 0 as number,
    data: {} as RegistrationData,
  }
}

export const registrationModule = {
  state: (): RegistrationState => (getDefaultState()),

  mutations: {
    setRegistrationRequiredScreens(state: RegistrationState, screens: Array<RegistrationComponent>) {
      state.requiredScreens = screens
    },

    setRegistrationActiveScreenIndex(state: RegistrationState, index?: number) {
      if(index === 0) {
        state.activeScreenIndex = index
      } else {
        state.activeScreenIndex++
      }

      state.activeScreens.push(state.requiredScreens[state.activeScreenIndex])
    },

    setRegistrationData(state: RegistrationState, data: RegistrationData) {
      Object.assign(state.data, data)
    },

    setRegistrationInitialState(state: RegistrationState) {
      Object.assign(state, getDefaultState())
    }
  },
};