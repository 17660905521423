import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "appButton" }
const _hoisted_2 = { class: "label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: _normalizeClass(_ctx.type),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.debouncedClick && _ctx.debouncedClick(...args)))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ], 2)
  ]))
}