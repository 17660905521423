import axios from 'axios';
import { config } from '@/helpers/environment.helper';
import { SpvMarketingRequestInterface } from './../types/interfaces';
import { ForumMessageInterface } from '@/types/forum';

export const launchpadApi = axios.create({
  baseURL: config.middlewareURL,
  timeout: 30000,
  withCredentials: true,
});

export const getLaunchpadAssets = (ids: any) => {
  return launchpadApi.get(`/launchpad/api/v2/assets?ids=${ids}`);
};

export const getAssetWithUrlNew = async (id: string) => {
  return await launchpadApi.get(`/launchpad/api/v2/asset/${id}?populateFeatures=true`);
};

export const getAssetRecords = (id: string) => {
  return launchpadApi.get(`/launchpad/api/v1/records/asset/${id}`);
};

export const getSpvMarketingDisplayPermission = (svpId?: string) => {
  return launchpadApi.get(
    `/launchpad/api/v1/spv/marketing/${svpId}/neededMarketingTypesForUser`
  );
};

export const postAssetPreOrder = (amount: number, assetId: string) => {
  return launchpadApi.post(`/launchpad/api/v1/asset/preorder`, { amount, assetId })
}

export const getMessagesWithAssetId = (id: string) => {
  return launchpadApi.get(`/launchpad/api/v1/forum/messages/${id}`)
}

export const getVotingWithAssetId = (id: string) => {
  return launchpadApi.get(`/launchpad/api/v1/forum/${id}/votings`)
}

export const postMessagesWithUrl = (body: ForumMessageInterface) => {
  return launchpadApi.post(`/launchpad/api/v1/forum/message`, body)
}

export const postManualInvestment = (shares: number, assetId: string, signatures?: Array<{name: string, file: string}>) => {
  return launchpadApi.post(`/launchpad/api/v1/asset/manual-investment`, { shares, assetId, signatures })
}

export const getNotaryDates = (assetId: string) => {
  return launchpadApi.get(`/launchpad/api/v1/asset/notary/${assetId}/date`);
};

export const getPowerplantPortfolioSerialNumbers = (assetId: string) => {
  return launchpadApi.get(`/launchpad/api/v1/asset/serialnumber/${assetId}`)
}

export const postReservedNotaryDate = (assetId: string, date: string, idAustria: boolean) => {
  return launchpadApi.post(`/launchpad/api/v1/asset/notary/${assetId}/date/reserve`, { date, idAustria })
};

export const postVoteOnSuggestion = (assetId: string, voteId: string) => {
  return launchpadApi.post(`/launchpad/api/v1/forum/${assetId}/voting/${voteId}/vote`)
}

export const deleteVoteOnSuggestion = (assetId: string, voteId: string) => {
  return launchpadApi.delete(`/launchpad/api/v1/forum/${assetId}/voting/${voteId}/vote`)
}

export const postNewVoting = (body: any) => {
  return launchpadApi.post(`/launchpad/api/v1/forum/voting`, body)
}

export const postVoteOnOption = (assetId: string, voteId: string, optionId: string) => {
  return launchpadApi.post(`/launchpad/api/v1/forum/${assetId}/voting/${voteId}/option/${optionId}/vote`)
}

export const deleteVoteOnOption = (assetId: string, voteId: string, optionId: string) => {
  return launchpadApi.delete(`/launchpad/api/v1/forum/${assetId}/voting/${voteId}/option/${optionId}/vote`)
}

export const postVotingOption = (assetId: string, voteId: string, data: any) => {
  return launchpadApi.post(`/launchpad/api/v1/forum/${assetId}/voting/${voteId}/option`, data)
}

export const postComplaint = (data: any) => {
  return launchpadApi.post(`/launchpad/api/v1/forum/complaint`, data)
}

export const postSpvMarketingOfUser = (
  id?: string,
  body?: SpvMarketingRequestInterface
) => {
  return launchpadApi.post(`/launchpad/api/v1/spv/marketing/${id}/user`, {
    marketingConsent: body,
  });
};
