export enum CopyType {
  IBAN = 'IBAN',
  BIC = 'BIC'
}

export enum BioEnum {
  null = 'null',
  face = 'face',
  finger = 'finger'
}

export enum CountryCode {
  Austria = 'de-AT',
  Switzerland = 'de-CH',
  Germany = 'de-DE'
}

export enum AccountState {
  Empty = 'Empty',
  NoTransactions = 'NoTransactions',
  Standard = 'Standard',
}

export enum OfferType {
  primary = 'primary',
  buy = 'buy',
  sell = 'sell'
}

export enum InvestmentState {
  EMPTY = 'empty',
  ONLY_OUTSTANDING_INVESTMENTS = 'onlyOutstandingInvestments',
  ONLY_PROCESSED_INVESTMENTS = 'onlyProcessedInvestments',
  ALL_INFORMATION_AVAILABLE = 'allInformationAvailable'
}

export enum TransactionMethods {
  creditCard = 'creditCard',
  directDebit = 'directDebit',
  applePay = 'applePay',
  googlePay = 'googlePay',
}

export enum TransactionTypes {
  buy = 'buy',
  sell = 'sell',
  charge = 'charge',
  payout = 'payout',
  rent = 'rent',
  bonus = 'bonus',
}

export enum TransactionStatuses {
  init = 'init',
  pending = 'pending',
  charged = 'charged',
  reserved = 'reserved',
  success = 'success',
  error = 'error',
  canceled = 'canceled',
  canceledOffer = 'canceledOffer',
  refund = 'refund',
  paymentMissing = 'paymentMissing',
  notaryMissing = 'notaryMissing'
}

export enum PrivateSecurityAppStatus {
  success = 'success',
  failed = 'failed'
}

export enum AssetFilterOptions {
  liked = 'liked',
  flat = 'flat',
  commercial = 'commercial',
  primaryMarket = 'primaryMarket',
  stock = 'stock',
  inConstruction = 'inConstruction',
  all = 'all',
  project = 'project',
  planning = 'planning',
  installed = 'installed',
  connectedToNetwork = 'connectedToNetwork'
}

export enum MarketplaceDisplayType {
  list = 'list',
  map = 'map'
}

export enum DesktopMenuTypes {
  MenuList = 'MenuList',
  RegistrationList = 'RegistrationList'
}

export enum BuilderOwnerModelKnowledgeExperienceScreens {
  BasicQuestions = 'BasicQuestions',
  RiskAppetite = 'RiskAppetite',
  InvestHorizon = 'InvestHorizon',
  InvestmentCapital = 'InvestmentCapital'
}

export enum TermsTypes {
  brickwise = 'brickwise',
  brickwise_customer = 'brickwise_customer',
  fourthline = 'fourthline',
  effecta = 'effecta',
  mangopay = 'mangopay',
  ip = 'ip',
  realpart = 'realpart',
  realpart_privacy = 'realpart_privacy'
}
