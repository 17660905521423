import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from '@/store'
import { StateActions } from './store/index';

import { IonicVue, isPlatform } from '@ionic/vue';
import VueGoogleMaps from '@fawmi/vue-google-maps';
import { checkForCachedUser } from '@/helpers/HelperFunctions';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

import i18n from './i18n/i18n';
import vueStore from './store';

import '@/assets/global.scss';

import SafeArea from '@/plugins/safearea.plugin';

if (isPlatform('capacitor')) {
  SafeArea.getSafeAreaInsets().then(({ insets }) => {
    if (isPlatform('ios')) {
      store.dispatch(StateActions.loadSafeAreaInsets, insets)
    }
  });
}

const app = createApp(App)
  .use(IonicVue, {
    swipeBackEnabled: isPlatform('ios') ? true : false,
  })
  .use(router)
  .use(i18n)
  .use(vueStore)
  .use(VueGoogleMaps, {
    load: {
      key: process.env.VUE_APP_GOOGLE_MAP_API_KEY,
      libraries: 'places'
    }
  });

router.isReady().then(async () => {
  await checkForCachedUser()
  app.mount('#app');
});

app.config.globalProperties.$filters = {
  currencyEur(value: number) {
    if (typeof value !== 'number') {
      return value;
    }
    const formatter = new Intl.NumberFormat('de-AT', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2
    });
    return formatter.format(value);
  },
  currencyEurShort(value: number) {
    if (typeof value !== 'number') {
      return value;
    }
    const formatter = new Intl.NumberFormat('de-AT', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 0
    });
    return formatter.format(value);
  },
  largeNumber(value: number) {
    if (typeof value !== 'number') {
      return value;
    }
    const formatter = new Intl.NumberFormat('de-DE', {
      minimumFractionDigits: 0
    });
    return formatter.format(value);
  },
  percentage(value: number) {
    if (typeof value !== 'number') {
      return value;
    }
    const formatter = new Intl.NumberFormat('de-AT', {
      style: 'percent',
      minimumFractionDigits: 2
    });
    return formatter.format(value);
  }
};
