<template>
  <div class="infoModalHolder p-48">
    <div class="infoModalContainer">
      <div class="title titleMedium mb-16">{{ info.header }}</div>
      <div class="text-holder" v-for="(item, i) in info.descriptions" :key="i">
        <div class="text paragraphExtraSmall mb-8" :class="{ underlined : item.link }" @click="item.link ? openPdf(item.link) : ''">{{ item.text }}</div>
      </div>
      <div v-if="info.inputs && info.inputs.length > 0" class="inputsContainer mt-16">
        <div class="inputItem" v-for="(item, i) in info.inputs" :key="i">
          <AppInput
            v-model="item.value"
            :label="item.label"
            :type="item.type"
            
          ></AppInput>
        </div>
      </div>
      <div v-if="info.buttons && info.buttons.length > 0" class="buttonsContainer mt-32">
        <div class="buttonItem" v-for="(item, i) in info.buttons" :key="i">
          <AppButton :type="item.type" @click="item.function()">{{ item.text }}</AppButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
//Components
import AppInput from '@/components/General/v2/AppInput.vue';
import AppButton from '@/components/General/AppButton.vue';
//Other
import PDFPlugin from '@/plugins/pdf.plugin';

export default defineComponent({
  name: 'InfoModal',
  components: {
    AppInput,
    AppButton
  },
  props: {
    info: Object as any
  },
  methods: {
    openPdf(link?: string) {
      if(link === '') return
      PDFPlugin.viewPDF({ url: link as string });
    },
  }
})
</script>

<style lang="scss">
.infoModalDesktop {
  --height: auto;
  
  &::part(backdrop) {
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
  }

  &::part(content) {
    border-radius: 16px;
  }

  .infoModalHolder {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    
    .infoModalContainer {
      .title {
        color: var(--navy800);
      }
      
      .text-holder {
        .text {
          color: var(--navy600);
          
          &.underlined {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }

      .buttonsContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 16px;

        .buttonItem {
          flex-grow: 1;
        }
      }
    }
  }
}
</style>
